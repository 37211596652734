
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

//let cartList = {};
//let modifierCartList = [];
let taxList = [];
let chargeList = [];
//let getMenus = [];
//let getItems = [];
let getTaxes = [];
//let getModifiers = [];
let getCharges = [];
class CartCalculation extends Component {

    static initiate() {
        // getMenus = sessionStorage.getItem("getMenuGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getMenuGroups"));
        // getItems = sessionStorage.getItem("getmenuItems") === null ? [] : JSON.parse(sessionStorage.getItem("getmenuItems"));
        // getModifiers = sessionStorage.getItem("getModifierGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getModifierGroups"));
        getTaxes = sessionStorage.getItem("getTaxes") === null ? [] : JSON.parse(sessionStorage.getItem("getTaxes"));
        getCharges = sessionStorage.getItem("getCharges") === null ? [] : JSON.parse(sessionStorage.getItem("getCharges"));

        taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
        chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));
    }

    static itemTaxCartCalculation(taxId, rate, qty, itemId, uid) {
        this.initiate();
        let cList = getTaxes.filter(x => x.taxId === taxId);
        let taxObj = [];
        let taxAmt = 0;
        let itemValue = rate * qty;
        let bInsertTax = false;
        if (cList.length > 0) {
            Array.prototype.forEach.call(cList[0].structure, tx => {
                bInsertTax = false;
                taxAmt = 0;
                //Applicable on = 2 Discount Value  && applicableon = 1 direct value
                if (tx.applicableOn === 3) { // Tax on Tax     
                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                    if (serialTax.length > 0) {
                        if (tx.taxType === 1) // (%)                                                
                            taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
                        else if (tx.taxType === 2)  // Amount
                            taxAmt = tx.taxValue * qty;

                        bInsertTax = true;
                    }
                }
                else {
                    if (tx.taxType === 1) // (%)             
                        taxAmt = itemValue * tx.taxValue * 0.01;
                    else if (tx.taxType === 2) // Amount
                        taxAmt = tx.taxValue * qty; // 

                    bInsertTax = true;
                }

                if (bInsertTax === true) {
                    taxObj.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                    taxList.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                }
            });
        }
        sessionStorage.setItem("taxCartList", JSON.stringify(taxList));

    }

    static itemChargeCartCalculation(chargeId, rate, qty, itemId, uid) {
        this.initiate();
        chargeId = chargeId === null ? [] : chargeId;
        if (chargeId.length > 0) {
            Array.prototype.forEach.call(chargeId, cId => {
                // filter based on Item wise & Item Qty wise => apply category
                let chargelist = getCharges.filter(x => x.chargeId === cId && x.applyTo === 1);
                let taxlist = [];
                let taxObj = [];
                let taxAmt = 0;

                let itemValue = rate * qty;
                let chargeAmt = 0;
                if (chargelist.length > 0) {
                    Array.prototype.forEach.call(chargelist, chg => {
                        let rnTaxAmt = 0;
                        if (chg.applyType === 2) { // slabe wise (%)   - only for slab base value from and value to (percentage =>itmvalue * per * 0.01) or amount=> direct slab amount)         
                            chg.chargeSlab = chg.chargeSlab === null ? [] : chg.chargeSlab;
                            let cslab = chg.chargeSlab.filter(x => x.ValueFrom > itemValue && x.ValueTo < itemValue)
                            if (cslab.length > 0) {
                                if (cslab.chargeValueType === 1)
                                    chargeAmt = itemValue * chg.chargeValue * 0.01;
                                else if (cslab.chargeValueType === 2)
                                    chargeAmt = cslab.ChargeValue;
                            }
                        }
                        else if (chg.chargeValueType === 2) // Amount only qty based 
                            chargeAmt = chg.chargeValue * qty; //                        
                        // Tax 
                        taxlist = getTaxes.filter(x => x.taxId === chg.taxId);
                        if (taxlist.length > 0) {
                            Array.prototype.forEach.call(taxlist[0].structure, tx => {
                                taxAmt = 0;
                                if (tx.applicableOn === 3) {
                                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                                    if (serialTax.length > 0) {
                                        if (tx.taxType === 1) // (%) 
                                            taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
                                        else if (tx.taxType === 2) // Amount
                                            taxAmt = tx.taxValue;
                                    }
                                }
                                else {

                                    if (tx.taxType === 1) // (%)             
                                        taxAmt = (chargeAmt * tx.taxValue * 0.01);
                                    else if (tx.taxType === 2) // Amount
                                        taxAmt = tx.taxValue; // 
                                }
                                rnTaxAmt += taxAmt;
                                taxObj.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, taxId: chg.taxId, taxGroupName: taxlist[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                            });
                        }
                        chargeList.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, chargeName: chg.chargeName, chargeType: chg.chargeType, isMerchantCharge: false, chargeValueType: chg.chargeValueType, chargeValue: chg.chargeValue, chargeAmount: Number((chargeAmt).toFixed(2)), taxes: taxObj, totalTaxAmount: Number((rnTaxAmt).toFixed(2)) });
                    });
                }
            });
        }
        sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));
    }

    /// Modifier
    static modifierItemTaxCartCalculation(taxId, rate, qty, itemId, uid) {

        this.initiate();
        let cList = getTaxes.filter(x => x.taxId === taxId);
        let taxObj = [];
        let taxAmt = 0;
        let itemValue = rate * qty;


        let bInsertTax = false;
        if (cList.length > 0) {
            Array.prototype.forEach.call(cList[0].structure, tx => {
                bInsertTax = false;
                taxAmt = 0;
                //Applicable on = 2 Discount Value  && applicableon = 1 direct value
                if (tx.applicableOn === 3) { // Tax on Tax     
                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                    if (serialTax.length > 0) {
                        if (tx.taxType === 1) // (%)                                                
                            taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
                        else if (tx.taxType === 2)  // Amount
                            taxAmt = tx.taxValue * qty;

                        bInsertTax = true;
                    }
                }
                else {
                    if (tx.taxType === 1) // (%)             
                        taxAmt = itemValue * tx.taxValue * 0.01;
                    else if (tx.taxType === 2) // Amount
                        taxAmt = tx.taxValue * qty; // 

                    bInsertTax = true;
                }

                if (bInsertTax === true) {
                    taxObj.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                }
            });
        }
        return taxObj;
    }

    static modifierItemChargeCartCalculation(chargeId, rate, qty, itemId, uid) {
        this.initiate();
        chargeId = chargeId === null ? [] : chargeId;
        let modifierChargeList = [];
        if (chargeId.length > 0) {
            Array.prototype.forEach.call(chargeId, cId => {
                // filter based on Item wise & Item Qty wise => apply category
                let chargelist = getCharges.filter(x => x.chargeId === cId && x.applyTo === 1 && x.applyType === 1);
                let taxlist = [];
                let taxObj = [];
                let taxAmt = 0;
                let itemValue = rate * qty;
                let chargeAmt = 0;
                if (chargelist.length > 0) {
                    Array.prototype.forEach.call(chargelist, chg => {
                        let rnTaxAmt = 0;
                        if (chg.applyType === 2) { // slabe wise (%)   - only for slab base value from and value to (percentage =>itmvalue * per * 0.01) or amount=> direct slab amount)         
                            chg.chargeSlab = chg.chargeSlab === null ? [] : chg.chargeSlab;
                            let cslab = chg.chargeSlab.filter(x => x.ValueFrom > itemValue && x.ValueTo < itemValue)
                            if (cslab.length > 0) {
                                if (cslab.chargeValueType === 1)
                                    chargeAmt = itemValue * chg.chargeValue * 0.01;
                                else if (cslab.chargeValueType === 2)
                                    chargeAmt = cslab.ChargeValue;
                            }
                        }
                        else if (chg.chargeValueType === 2) // Amount only qty based 
                            chargeAmt = chg.chargeValue * qty; //                        
                        // Tax 
                        taxlist = getTaxes.filter(x => x.taxId === chg.taxId);
                        if (taxlist.length > 0) {
                            Array.prototype.forEach.call(taxlist[0].structure, tx => {
                                taxAmt = 0;
                                if (tx.applicableOn === 3) { //tax on tax
                                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                                    if (serialTax.length > 0) {
                                        if (tx.taxType === 1) // (%) 
                                            taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
                                        else if (tx.taxType === 2) // Amount
                                            taxAmt = tx.taxValue;
                                    }
                                }
                                else {
                                    if (tx.taxType === 1) // (%)             
                                        taxAmt = (chargeAmt * tx.taxValue * 0.01);
                                    else if (tx.taxType === 2) // Amount
                                        taxAmt = tx.taxValue; // 
                                }
                                rnTaxAmt += taxAmt;
                                taxObj.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, taxId: chg.taxId, taxGroupName: taxlist[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                            })
                        }
                        modifierChargeList.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, chargeName: chg.chargeName, chargeType: chg.chargeType, isMerchantCharge: false, chargeValueType: chg.chargeValueType, chargeValue: chg.chargeValue, chargeAmount: Number((chargeAmt).toFixed(2)), taxes: taxObj, totalTaxAmount: rnTaxAmt });

                    });
                }
            });
        }
        return modifierChargeList;
    }

    // On Bill Tax(Item total + ItemTaxTotal(with modifier) - Discount = Bill Value on Tax )
    static OnBillTaxCalculation(billValue) {
        this.initiate();
        let cList = getTaxes.filter(x => x.applyTo === 2); // on Bill Type
        let taxObj = [];
        let taxAmt = 0;
        let bInsertTax = false;
        let onBillTaxValue = 0;
        if (cList.length > 0) {
            Array.prototype.forEach.call(cList[0].structure, tx => {
                bInsertTax = false;
                taxAmt = 0;
                //Applicable on = 2 Discount Value  && applicableon = 1 direct value
                if (tx.applicableOn === 3) { // Tax on Tax     
                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                    if (serialTax.length > 0) {
                        if (tx.taxType === 1) // (%)                                                
                            taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
                        else if (tx.taxType === 2)  // Amount
                            taxAmt = tx.taxValue;

                        bInsertTax = true;
                    }
                }
                else {
                    if (tx.taxType === 1) // (%)             
                        taxAmt = billValue * tx.taxValue * 0.01;
                    else if (tx.taxType === 2) // Amount
                        taxAmt = tx.taxValue; // 

                    bInsertTax = true;
                }

                if (bInsertTax === true) {
                    onBillTaxValue += taxAmt;
                    // taxObj.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                    // taxList.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                }
            });
        }
        return onBillTaxValue;
    }

    // Order Level Charges ( Apply To = 1:- Item wise and *ApplyTo=2 :- Order Level)
    static OrderLevelCharge(itemTotal) {
        this.initiate();
        let totalChargeAmount = 0;
        let totalChargeTaxAmount = 0;
        // filter based on Item wise & Item Qty wise => apply category
        let chargelist = getCharges.filter(x => x.applyTo === 2);
        let taxlist = [];
        let taxObj = [];
        let taxAmt = 0;
        let orderLeveChargeList = [];
        let itemValue = itemTotal;
        let chargeAmt = 0;
        if (chargelist.length > 0) {
            Array.prototype.forEach.call(chargelist, chg => {
                let rnTaxAmt = 0;
                chargeAmt = 0;
                if (chg.applyType === 2) { // slabe wise (%)   - only for slab base value from and value to (percentage =>itmvalue * per * 0.01) or amount=> direct slab amount)         
                    chg.chargeSlab = chg.chargeSlab === null ? [] : chg.chargeSlab;
                    let cslab = chg.chargeSlab.filter(x => x.ValueFrom > itemValue && x.ValueTo < itemValue)
                    if (cslab.length > 0) {
                        if (cslab.chargeValueType === 1)
                            chargeAmt = itemValue * chg.chargeValue * 0.01;
                        else if (cslab.chargeValueType === 2)
                            chargeAmt = cslab.ChargeValue;
                    }
                }
                else if (chg.chargeValueType === 2) // Amount only qty based 
                    chargeAmt = chg.chargeValue; //                        
                // Tax 
                taxlist = getTaxes.filter(x => x.taxId === chg.taxId);
                if (taxlist.length > 0) {
                    Array.prototype.forEach.call(taxlist[0].structure, tx => {
                        taxAmt = 0;
                        if (tx.applicableOn === 3) {
                            let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                            if (serialTax.length > 0) {
                                if (tx.taxType === 1) // (%) 
                                    taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
                                else if (tx.taxType === 2) // Amount
                                    taxAmt = tx.taxValue;
                            }
                        }
                        else {

                            if (tx.taxType === 1) // (%)             
                                taxAmt = (chargeAmt * tx.taxValue * 0.01);
                            else if (tx.taxType === 2) // Amount
                                taxAmt = tx.taxValue; // 
                        }
                        rnTaxAmt += taxAmt;
                        taxObj.push({ chargeId: chg.chargeId, taxId: chg.taxId, taxGroupName: taxlist[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                    });
                }
                orderLeveChargeList.push({ chargeId: chg.chargeId, chargeName: chg.chargeName, chargeType: chg.chargeType, isMerchantCharge: false, chargeValueType: chg.chargeValueType, chargeValue: chg.chargeValue, chargeAmount: Number((chargeAmt).toFixed(2)), taxes: taxObj, totalTaxAmount: Number((rnTaxAmt).toFixed(2)) });
                totalChargeTaxAmount += rnTaxAmt;
                totalChargeAmount += chargeAmt;
            });
        }
        sessionStorage.setItem('order-charge-Amount', totalChargeAmount);
        sessionStorage.setItem('order-charge-Tax-Amount', totalChargeTaxAmount);
        sessionStorage.setItem('order-charge-List', JSON.stringify(orderLeveChargeList));
    }
}

export default withRouter(CartCalculation)