import React, { Component } from 'react';
import './groupmembers.scss';
import 'react-tabs/style/react-tabs.css';
import Config from '../../data/SiteConfig';

let mobileNo = sessionStorage.getItem('user-mobileno');
export default class GroupMembers extends Component {
    constructor(props) {
        super(props)
        this.handleGroupMemberList = this.handleGroupMemberList.bind(this);
        this.state = {
            groupMemberList: []
        }

    }

    handleGroupMemberList() {
        this.getGuestDashboardDetails();
        this.inputElement.click();

    }


    getGuestDashboardDetails() {

        let mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        let token = sessionStorage.getItem('user-token');
        let serviceType = sessionStorage.getItem('user-servicetype');
        let propertyId = sessionStorage.getItem('user-propertyid');
        let outletCode = sessionStorage.getItem('user-outletcode');
        let tableNo = sessionStorage.getItem('user-tableno');
        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType + '&TableNumber=' + tableNo + '&guestmobileNumber=' + mobileNo;
        //console.log(urlParam);
        fetch(Config.basePOSUrl + 'GetGuestAppDashBoardDetails' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                //  console.log(response);
                if (response.errorCode === 0) {
                    if (mobileNo === mainGuestMobileNo) {
                        this.setState({ groupPin: response.response.groupDetails.groupCode });
                        let groupPin = response.response.groupDetails.groupCode;
                        sessionStorage.setItem('group-pin', groupPin);
                    }
                    else
                        this.setState({ groupPin: "" });

                    this.setState({ groupMemberList: response.response.groupDetails.guestDetails });
                    // this.getDetails(false);

                }
                else {
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                ////console.log(err);            
                this.showMessage('An error occured - ' + err, "danger");
            });
    }


    render() {
        return (
            <>
                <div className="section-group-members">
                    <div className="menu-goup">
                        <button className="theme-button btn" style={{ display: "none" }} ref={input => this.inputElement = input} data-toggle="modal" data-target="#groupmembers-modal"><i className="fa fa-utensils sidebar-icon"></i> MENU1</button>
                    </div>
                    {/* Show Modifier*/}
                    <div className="modal fade" id="groupmembers-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content animate-bottom">
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Group Members
                                    </h5>

                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" id="div-groupmembers-body">
                                    {this.state.groupMemberList.length > 0 &&
                                        <div className="group-content">
                                            <div className="group-list">
                                                <ul>
                                                    {
                                                        this.state.groupMemberList.map(m =>
                                                            <li className="people" key={m.guestMobileNumber}>
                                                                {m.guestName === '' && <div> <i className="fas fa-mobile-alt"></i> <span className="people-content">{m.guestMobileNumber}{m.isMainGuest && <span className="people-main"> (Main)</span>}{(m.guestMobileNumber === mobileNo && !m.isMainGuest) && <span> (You)</span>}</span></div>}
                                                                {m.guestName !== '' && <div> <i className="fas fa-user-alt"></i> <span className="people-content">{m.guestName}{m.isMainGuest && <span className="people-main"> (Main)</span>}{(m.guestMobileNumber === mobileNo && !m.isMainGuest) && <span> (You)</span>}</span></div>}
                                                            </li>

                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }

                                    {/* {this.handleGenerateModifierDom()} */}
                                    {/* <ul className="modifier-group-li">
                                        {this.props.modifierGroupIds.map(mid =>
                                            <div key={"modifier-group-id-" + mid}>
                                                <li key={mid}>
                                                    {this.props.modifierList.filter(mgrplst => mgrplst.modifierGroupId === mid).map(mgrp =>
                                                        <div key={"modifier-group-" + mgrp.modifierGroupId}>
                                                            <span className="group-name">{mgrp.minimumSelection > 0 && <span className="minimum-selection">*</span>}{mgrp.groupName}</span>

                                                            {mgrp.maximumSelection === 0 &&
                                                                <span> (<span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>0</span>)</span>
                                                            }
                                                            {mgrp.maximumSelection > 0 &&
                                                                <span> (
                                                                <span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>0</span>
                                                                /
                                                                <span id={"max-group-modifier-cnt-" + mgrp.modifierGroupId}>{mgrp.maximumSelection}</span>
                                                                )</span>
                                                            }

                                                            <ul className="modifier-li">
                                                                {mgrp.modifiers.map(mlst =>
                                                                    <li key={"modifier-li-" + mlst.modifierId}>
                                                                        {mgrp.maximumSelection === 2 && <Checkbox name={mlst.modifierName} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.checkedItems.get(mlst.modifierName)} onChange={(e) => this.handleChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Checkbox>}
                                                                        {mgrp.maximumSelection === 1 && <Radio name={mgrp.modifierGroupId} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.radiostatus} onChange={(e) => this.handleRadioChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Radio>}
                                                                        {mgrp.maximumSelection === 0 && <Checkbox name={mlst.modifierName} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.checkedItems.get(mlst.modifierName)} onChange={(e) => this.handleChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Checkbox>}
                                                                        {mlst.foodType === 1 && <img className="food-type-img" src={"../assets/theme1/img/f-t-veg.png"} alt="item" />}
                                                                        {mlst.foodType === 2 && <img className="food-type-img" src={"../assets/theme1/img/f-t-nonveg.png"} alt="item" />}
                                                                        {mlst.modifierName}<span className="rate-inr"> ₹{mlst.rate}</span>
                                                                    </li>

                                                                )
                                                                }
                                                            </ul>
                                                        </div>
                                                    )}
                                                </li>
                                            </div>
                                        )}
                                    </ul> */}
                                </div>
                                {/* <div className="modal-footer">
                                    <div className="cart" >
                                        <div className="c-left"> Item Total  ₹<span >110</span></div>
                                        <div className="c-right" data-dismiss="modal">Add Item</div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div >
            </>

        );
    }
}
