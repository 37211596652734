import React, { Component } from 'react';
import './qrcodeerror.scss';
export default class Offline extends Component {     
    
    constructor(props)
    {
        super(props)
        this.gotoOnline = this.gotoOnline.bind(this);
    }

    gotoOnline()
    {
        this.props.history.goBack();
    }
    
    render()
    {
        return(
            <div>
            {/* {loading && <LoadingAnimation />}             */}
            <div id="login-content qrcode-err">
                <div className="content-wrap qrcode_bg">
                    {/* <img src={LoadingImg} className="svg" alt="" />  */}
                    <img src="/img/error/internet-error.png" className="orentation-img" alt="lucid-contactless-food-ordering" />
                  
                    <div className="qrcode-txt-center">
                        {/* <img src="../assets/theme1/img/error/orientation-mode.png" style={{width: "50px"}} alt="lucid-device-error"/> */}
                        <div className="qrcode-oops-txt">No internet connection</div>
                        {/* <div className="qrcode--txt-not-found">404 - Page Not Found.</div> */}
                        <div className="qrcode-msg-txt">Make sure Wi-Fi or mobile data is turned on,
                        <br/> then try again
                        </div>            
                                      {/* <div className="qrcode-msg-txt">The page you are looking for might have been removed <br/>
                        had its name changed or is temporarily unavailable.
                        </div> */}
                    </div>
                    <div>
                    <button className="retyr-btn" onClick={this.gotoOnline} id="input-btn-send-otp">Retry</button>
                    </div>
                </div>
                {/* <img src={LoadingImg} className="svg screen-img-center loading_img" alt="" /> */}
            </div>
        </div>
        )
    }
}