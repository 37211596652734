import React from 'react'
import loading_animation from './loading-bars.svg'
import styles from './loading.module.sass'
export default function loadingpage() {
    return (
        <>
            <div className={styles.loading_background_color} >
                <div className={styles.loading_img}>
                    <img src={loading_animation} className={styles.svg} alt="lucid-contactless-app" />
                </div>
            </div>
            <div className={styles.loading_txt}>Loading...</div>
        </>
    )
}
