import React from 'react'
import loading_animation from './loading-bars.svg'
import styles from './loading.module.sass'
export default function index() {
  return (
    <div className={styles.loading_background} >
      <div className={styles.loading_img}><img src={loading_animation} className={styles.svg} alt="" /></div>
    </div>
  )
}
