import React from 'react';
import PropTypes from 'prop-types';
const Radio = ({ type = 'radio', name, id, checked, onChange, value }) => (
  <input type={type} name={name} id={id} checked={checked} onChange={onChange} value={value} />
);

Radio.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
}

export default Radio;