import React from 'react';
import PropTypes from 'prop-types';
const Checkbox = ({ type = 'checkbox', name,id, checked = false,access, onChange }) => (
    <input type={type} name={name} id={id} checked={checked}  onChange={onChange} disabled = {access} />
  );
  
  Checkbox.propTypes = {
    type: PropTypes.string,    
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,  
    checked: PropTypes.bool,       
    access: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
  }  
  
  export default Checkbox;