import React, { Component } from 'react';
import './cart.scss';
import Config from '../../data/SiteConfig';
import enmConfig from '../../data/enumConfig';
//import uuid from 'react-uuid';
//import Cookies from 'universal-cookie';
import LoadingAnimation from '../../components/loadinganimation';
import ErrorAlert from '../../components/alertmessages';
import CartCalCulation from '../../modules/cart/cartcalculation';
import { isMobile } from 'react-device-detect';
//import sHelpers from '../../modules/helpers/statichelpers';
import AlertMessagePopup from '../../components/popup/alertmessage';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import sHelpers from '../../modules/helpers/statichelpers';
import Currency from '../../components/currency/index';
import { config } from 'react-transition-group';

let totalItems = 0;
let totalItemAmount = 0;
let totalNetAmount = 0;
let cartList = {};
let modifierCartList = [];
let taxList = [];
let chargeList = [];
let orderChargeList = [];
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let orgnationTd = '';
let propertyId = '';
let outletCode = '';
let totalChargesAmount = 0;
let totaltaxAmount = 0;
let outletDetails = null;
let olDetail = {};
let orderCheckoutType = 0;
let mainGuestMobileNo = '';
let groupPin = '';
let guestLoggedIn = "false";
let guestDetailsStr = null;
let guestDetails = {};
let guestCurrentAddress = null;
let guestName = '';
// let otp = '';
// let tablePin = '';

// Validate Order

// let vaildCartList = {};
// let vaildModifierCartList = [];
// let vaildTaxList = [];
// let vaildChargeList = [];
let vailduniquesGroups = [];
// let vaildtotalItemAmount = 0;
// let vaildtotalItemTaxAmount = 0;
// let vaildtotalItemChargesAmount = 0;


// let vaildtotalChargesAmount = 0;
// let vaildtotalTaxAmount = 0;
// let vaildtotalAmount = 0;
// let vaildVendorOrderId = '';
let userSessionId = '';
let urlPattern = '';
const alertMessageRef = React.createRef();
const alertContentRef = React.createRef();
export default class Cart extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.goPayments = this.goPayments.bind(this);
        this.handleValidateOrder = this.handleValidateOrder.bind(this);
        this.alertPopupRef = React.createRef();
        //this.alertContentRef = React.createRef();
        //this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
        this.state = {
            loading: false,
            // cartItemList: [
            //     { mnuId: 1, mnuName: "Chilli Chicken", price: "166", qty: 2, foodType: 2, modifier: [] },
            //     { mnuId: 2, mnuName: "Chilli Paneer", price: "178", qty: 1, foodType: 1, modifier: [{ modifierId: 1, modifierName: "spicy", price: 10 }, { modifierId: 2, modifierName: "spicy", price: 10 }] },
            //     { mnuId: 3, mnuName: "Veg Manchurian", price: "127", qty: 1, foodType: 2, modifier: [{ modifierId: 3, modifierName: "jam", price: 22 }] }
            // ],
            cartItemList: [
                // { mnuId: 1, mnuName: "Chilli Chicken", price: "166", qty: 2, foodType: 2, modifier: [] },
                // { mnuId: 2, mnuName: "Chilli Paneer", price: "178", qty: 1, foodType: 1, modifier: [{ modifierId: 1, modifierName: "spicy", price: 10 }, { modifierId: 2, modifierName: "spicy", price: 10 }] },
                // { mnuId: 3, mnuName: "Veg Manchurian", price: "127", qty: 1, foodType: 2, modifier: [{ modifierId: 3, modifierName: "jam", price: 22 }] }
            ],
            getMenus: [],
            getItems: [],
            getModifiers: [],
            getTaxes: [],
            getCharges: [],
            orderId: 0,
            orderInstruction: "",
            alertMessage: '',
            msgType: '',
            msgShow: false,
            totalItems: 0,
            totalItemAmount: 0,
            totalAmount: 0,
            totalNetAmount: 0,
            totalChargesAmount: 0,
            totaltaxAmount: 0,
            modifierItemList: [],
            roundOff: 0,

            // added by dasprakash on 21-10-2023 jira-1644
            errorMessage: '',
            closeButton: false,
            contentMessage: '',
            alertMessages: '',
        }

    };
    componentWillMount() {

        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        // tablePin = sessionStorage.getItem('table-pins');
        // otp = sessionStorage.getItem('otp');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationTd = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        outletDetails = sessionStorage.getItem('user-outletDetails');
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        groupPin = sessionStorage.getItem('group-pin') == null ? '' : sessionStorage.getItem('group-pin');
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        guestDetailsStr = sessionStorage.getItem('user-GuestDetails');
        urlPattern = sessionStorage.getItem('url-pattern');
        //sessionStorage.removeItem("order-instruction");
        // sessionStorage.removeItem("user-vendorOrderId");
        guestCurrentAddress = sessionStorage.getItem('user-CurrentAddress') === null ? null : JSON.parse(sessionStorage.getItem('user-CurrentAddress'));
        guestName = sessionStorage.getItem('guest-name') === null ? '' : sessionStorage.getItem('guest-name');
        userSessionId = sessionStorage.getItem('user-session-id') == null ? "" : sessionStorage.getItem('user-session-id');
        // remove Order charges
        sessionStorage.removeItem('order-charge-Amount');
        sessionStorage.removeItem('order-charge-Tax-Amount');
        sessionStorage.removeItem('order-charge-List');
        sessionStorage.removeItem('proceed-pod');


        // //console.log(mobileNo);
        // //console.log(token);
        // //console.log(tableNo);
        // //console.log(serviceType);
        // //console.log(orgnationTd);
        // //console.log(propertyId);
        // //console.log(outletCode);
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }

        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (!urlPattern) {
            // Handle error or redirect as needed
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationTd === undefined || orgnationTd === "" || orgnationTd === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (outletDetails === undefined || outletDetails === "" || outletDetails === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            olDetail = JSON.parse(outletDetails);
            orderCheckoutType = olDetail.orderCheckOutType;
        }

        if (guestDetailsStr === undefined || guestDetailsStr === "" || guestDetailsStr === null) {
            guestDetails = null;
        }
        else {
            guestDetails = JSON.parse(guestDetailsStr);
        }

        window.scrollTo(0, 0);
        document.body.classList.remove('modal-open');
        let modalPopup = document.querySelector('.modal-backdrop');
        if (modalPopup !== null) {
            modalPopup.classList.remove('modal-backdrop', 'fade', 'show');
        }

        /// Session Validation for Fine Dine
        // if (serviceType === "FINE-DINE") {
        //     if (!sHelpers.sessionValidation()) {
        //         //this.showMessage("Your session has expired, please scan once again", "danger");
        //         this.setState({ loading: true });
        //         setTimeout(() => {
        //             this.setState({ loading: false });
        //             this.props.history.push('/logout');
        //         }, 3000);
        //         return false;
        //     }
        // }




        this.handleCartRecalculate();
        /// Check the Logged in Mode Click
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.onClick !== undefined || this.props.location.state.onClick !== null || this.props.location.state.onClick !== false) {
                this.setState({ loading: true });

                setTimeout(() => {
                    this.setState({ loading: false });
                    let typeId = sessionStorage.getItem('on-click-mode') === null ? 0 : parseInt(sessionStorage.getItem('on-click-mode'));
                    if (typeId > 0) {
                        if (serviceType !== Config.serviceType.DELIVERY)
                            this.handleValidateOrder(typeId, true);
                    };
                }, Config.minMsgTimeout);
            }
        }

        this.getCMSDetails();
    };

    componentDidMount() {

        this.handleCartRecalculate();

        // get unique modifier group
        //set Order Instruction
        this.setState({ orderInstruction: (sessionStorage.getItem("order-instruction") === null || sessionStorage.getItem("order-instruction") === undefined) ? '' : sessionStorage.getItem("order-instruction") });

        // var id=sessionStorage.getItem('user-vendorOrderId');
        // console.log(id,'id');
        // //console.log(_uniquesGroups);
    };

    getCMSDetails() {
        const postobj = {
            organizationId: parseInt(orgnationTd),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ApplicationCode: Config.applicationCode,
            PageId: Config.pageIds.cart, // Default Menu
            PageType: Config.pageType.cart, // Menu           
            RequestIp: "",
            username: Config.cmsUserName,
            password: Config.cmsPassword
        };

        fetch(`${Config.baseAPIUrl}PageDetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postobj)
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`Network response was not ok: ${res.statusText}`);
                }
                return res.json();
            })
            .then(response => {
                if (response.isSuccessful) {
                    console.log("response", response);
                    sessionStorage.setItem("pageCMSSettings", JSON.stringify(response));
                    this.setState({
                        contentMessage: response.page.content, // Correctly set the content message
                        loading: false // Loading completed
                    });
                }
                // else {
                //     this.setState({
                //         errorMessage: response.message, // Set error message
                //         loading: false // Loading completed
                //     });
                //     this.showMessage(response.message, "danger");
                // }
            })
            .catch(err => {
                // this.setState({
                //     loading: false, // Loading completed
                //     errorMessage: "CMS - Failed to fetch" // Set error message
                // });
                // this.showMessage("CMS - Failed to fetch", "danger");
                console.error("Fetch error:", err);
            });
    }

    handlePopupAlertMessage() {
        if (alertMessageRef.current) {
            alertMessageRef.current.handlePopupAlertMessage();
        }
    };


    handleContent = () => {
        const { contentMessage } = this.state;
        return contentMessage.length > 90 ? (
            <>
                {contentMessage.slice(0, 90) + "..."}


                <span
                    onClick={this.handlePopupContentMessage}

                    style={{ color: "#000", fontWeight: "500", fontSize: "11px", cursor: "pointer" }}

                >
                    more
                </span>
            </>
        ) : (
            contentMessage
        );
    };

    handlePopupContentMessage = () => {
        this.setState({ alertMessages: this.state.contentMessage });
        if (alertContentRef.current) {
            alertContentRef.current.handlePopupContentMessage();
        }
    };


    handleCartRecalculate() {
        // re calculate cart value
        let cartItemValue = 0;
        let cartItemQty = 0;
        let modifierCartValue = 0;
        let onBillTaxValue = 0;
        let orderLevelCharge = 0;
        let orderLevelChargeTaxAmount = 0;
        totaltaxAmount = 0;
        totaltaxAmount = 0;
        totalChargesAmount = 0;

        totalItemAmount = 0;
        totalNetAmount = 0;
        totalItems = 0;


        cartList = sessionStorage.getItem("cartList") === null ? [] : JSON.parse(sessionStorage.getItem("cartList"));
        taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
        modifierCartList = sessionStorage.getItem("modifierCartList") === null ? [] : JSON.parse(sessionStorage.getItem("modifierCartList"));
        chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));


        if (sessionStorage.getItem("cartList") !== null) {
            Array.prototype.forEach.call(cartList.itemList, itm => {
                cartItemValue += (itm.rate * itm.qty);
                cartItemQty += itm.qty;
                // //console.log(cartItemValue);
                let mdcartItemList = modifierCartList.filter(x => x.uid === itm.uid);
                // //console.log(mdcartItemList);
                Array.prototype.forEach.call(mdcartItemList, md => {
                    modifierCartValue += md.rate * itm.qty;
                });
            });

            totaltaxAmount = taxList.reduce((prev, next) => prev + next.taxAmount, 0);
            totaltaxAmount += chargeList.reduce((prev, next) => prev + next.totalTaxAmount, 0);
            totaltaxAmount += modifierCartList.reduce((prev, next) => prev + next.totalTaxAmount, 0);
            totalChargesAmount = chargeList.reduce((prev, next) => prev + next.chargeAmount, 0);
            totalChargesAmount += modifierCartList.reduce((prev, next) => prev + next.totalChargeAmount, 0);

            totalItemAmount = cartItemValue + modifierCartValue;
            onBillTaxValue = CartCalCulation.OnBillTaxCalculation(totalItemAmount);
            // //console.log(onBillTaxValue);
            CartCalCulation.OrderLevelCharge(totalItemAmount);
            orderLevelCharge = sessionStorage.getItem('order-charge-Amount') === null ? 0 : parseFloat(sessionStorage.getItem('order-charge-Amount'));
            orderLevelChargeTaxAmount = sessionStorage.getItem('order-charge-Tax-Amount') === null ? 0 : parseFloat(sessionStorage.getItem('order-charge-Tax-Amount'));
            // //console.log(orderLevelCharge);
            totalChargesAmount += orderLevelCharge;
            totaltaxAmount += onBillTaxValue;
            totaltaxAmount += orderLevelChargeTaxAmount;
            totalNetAmount = totalItemAmount + totalChargesAmount + totaltaxAmount;
            totalItems = cartItemQty;

            cartList.totalItems = totalItems;
            cartList.totalAmount = totalItemAmount;
            sessionStorage.setItem("cartList", JSON.stringify(cartList));


            // round off
            let roundOffAmt = sHelpers.roundOff(olDetail.roundOffType, totalNetAmount, olDetail.roundOffValue);
            let rndOffAmt = roundOffAmt - totalNetAmount;
            totalNetAmount = roundOffAmt;
            // roundOff = roundOffAmt - totalNetAmount;
            ////console.log(roundOff.toFixed(2));

            this.setState({
                totaltaxAmount: totaltaxAmount,
                totalChargesAmount: totalChargesAmount,
                totalItemAmount: totalItemAmount,
                totalNetAmount: totalNetAmount,
                totalItems: cartItemQty,
                modifierItemList: modifierCartList,
                cartItemList: cartList.itemList,
                roundOff: rndOffAmt
            });
        }
        if (totalItems === 0) {
            this.setState({ loading: true });
            setTimeout(() => {
                sessionStorage.removeItem("cartList");
                sessionStorage.removeItem("taxCartList");
                sessionStorage.removeItem("modifierCartList");
                sessionStorage.removeItem("chargeCartList");
                sessionStorage.removeItem("paymentInitiatedId");
                sessionStorage.removeItem("paymentStatus");
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, Config.minMsgTimeout);
        }
    };

    goBack() {
        //this.props.history.goBack();
        this.props.history.push('/items' + urlPattern);
    };

    goPayments(checkoutTypeId) {

        this.setState({ loading: true });

        if (this.state.totalNetAmount > 0) {
            this.handleValidateOrder();
            // setTimeout(() => {
            //     this.setState({ loading: false });
            //     sessionStorage.setItem("order-instruction", this.state.OrderInstruction);
            //     this.props.history.push('/payment', { totalNetAmount: this.state.totalNetAmount, totalItems: this.state.totalItems });
            // }, 2000);
        }
        else {
            // added by dasprakash on 21-10-2023 jira-1644
            this.setState({ errorMessage: "Item details are required" })
            //this.showMessage("Item details are required", "danger");
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, Config.minMsgTimeout);
        }
    }

    handleQtyIncDec(itmId, val, rate, itmName, itemId, groupId, foodType, isModifier, uid, taxId, chargeId) {
        if (isModifier === 0) {
            let qty = document.getElementById("" + uid + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + uid + "").innerHTML);
            let _qtyVal = val === 1 ? qty += 1 : qty -= 1;
            if (qty >= 0) {
                this.setState({ qty: _qtyVal });
                document.getElementById("" + uid + "").innerHTML = _qtyVal;
                totalItems = val === 1 ? totalItems += 1 : totalItems -= 1;
                totalItemAmount = val === 1 ? totalItemAmount += rate : totalItemAmount -= rate;
            }
            //let _amtVal = _qtyVal * rate;
            // totalAmount += _amtVal;

            this.setState({ totalItems: totalItems });
            this.setState({ totalItemAmount: totalItemAmount });

            cartList.totalItems = totalItems;
            cartList.totalAmount = totalItemAmount;

            let index = cartList.itemList.findIndex(x => x.uid === uid);
            // if (index < 0) {
            //     cartList.itemList.push({ id: "" + itmId + "", itemId: itemId, groupId: groupId, foodType: foodType, qty: _qtyVal, rate: rate, itemName: itmName, isModifier: 0, uid: "" });
            // }
            if (index > -1) {
                if (_qtyVal > 0) {

                    // tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    });

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        chargeList.splice(chgidx, 1);
                    });

                    cartList.itemList[index].qty = _qtyVal;

                    sessionStorage.setItem("cartList", JSON.stringify(cartList));
                    sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
                    sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));
                    CartCalCulation.itemTaxCartCalculation(taxId, rate, _qtyVal, itemId, cartList.itemList[index].uid);
                    CartCalCulation.itemChargeCartCalculation(chargeId, rate, _qtyVal, itemId, cartList.itemList[index].uid);
                    this.handleCartRecalculate();
                }
                else {

                    // tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    });

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        chargeList.splice(chgidx, 1);
                    });

                    cartList.itemList.splice(index, 1);

                    sessionStorage.setItem("cartList", JSON.stringify(cartList));
                    sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
                    sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));
                    this.handleCartRecalculate();
                }

            }


        }
        else { // modifier Items

            // cart
            let index = cartList.itemList.findIndex(x => x.uid === "" + uid + "");

            if (index > -1)
            //  cartList.itemList.push({ id: "" + itmId + "", itemId: itemId, groupId: groupId, foodType: foodType, qty: _qtyVal, rate: rate, itemName: itmName, isModifier: 0, uid: "" });
            {

                let qty = document.getElementById("" + uid + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + uid + "").innerHTML);
                let _qtyVal = val === 1 ? qty += 1 : qty -= 1;

                if (_qtyVal > 0) {
                    this.setState({ qty: _qtyVal });
                    document.getElementById("" + uid + "").innerHTML = _qtyVal;
                    totalItems = val === 1 ? totalItems += 1 : totalItems -= 1;
                    totalItemAmount = val === 1 ? totalItemAmount += rate : totalItemAmount -= rate;

                    this.setState({ totalItems: totalItems });
                    this.setState({ totalItemAmount: totalItemAmount });

                    cartList.totalItems = totalItems;
                    cartList.totalAmount = totalItemAmount;

                    // tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    })

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        chargeList.splice(chgidx, 1);
                    });

                    // modifier
                    // charge
                    let exmodifierList = modifierCartList.filter(x => x.uid === cartList.itemList[index].uid);
                    Array.prototype.forEach.call(exmodifierList, md => {
                        let txList = CartCalCulation.modifierItemTaxCartCalculation(md.taxId, md.rate, _qtyVal, md.itemId, md.uid);
                        let txAmount = txList.reduce((prev, next) => prev + next.taxAmount, 0);
                        let chgList = CartCalCulation.modifierItemChargeCartCalculation(md.chargeIds, md.rate, _qtyVal, md.itemId, md.uid);
                        let chgAmount = chgList.reduce((prev, next) => prev + next.taxAmount, 0);
                        md.taxes = txList;
                        md.charges = chgList;
                        md.totalTaxAmount = txAmount;
                        md.totalChargeAmount = chgAmount;

                    });

                    cartList.itemList[index].qty = _qtyVal;

                    sessionStorage.setItem("cartList", JSON.stringify(cartList));
                    sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
                    sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));
                    sessionStorage.setItem("modifierCartList", JSON.stringify(modifierCartList));

                    CartCalCulation.itemTaxCartCalculation(taxId, rate, _qtyVal, itemId, cartList.itemList[index].uid);
                    CartCalCulation.itemChargeCartCalculation(chargeId, rate, _qtyVal, itemId, cartList.itemList[index].uid);
                    this.handleCartRecalculate();
                }
                else {
                    // tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    })

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        // //console.log(chgidx);
                        chargeList.splice(chgidx, 1);
                    });

                    // modifier
                    // charge
                    let exmodifierList = modifierCartList.filter(x => x.uid === cartList.itemList[index].uid);
                    let mdidx = -1;
                    Array.prototype.forEach.call(exmodifierList, md => {
                        mdidx = modifierCartList.findIndex(x => x.uid === md.uid);
                        modifierCartList.splice(mdidx, 1);
                    });

                    cartList.itemList.splice(index, 1);
                    sessionStorage.setItem("cartList", JSON.stringify(cartList));
                    sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
                    sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));
                    sessionStorage.setItem("modifierCartList", JSON.stringify(modifierCartList));
                    this.handleCartRecalculate();

                }
            }
        }
    };

    handleInstrucChange = event => {
        const value = event.target.value;
        this.setState({ orderInstruction: value, closeButton: true });
        //  //console.log(value);
    };

    guestTableCheck(typeId) {
        this.setState({ loading: true });

        // Check Loggin Process
        //  mobileNo = sessionStorage.getItem('user-mobileno');

        if (mobileNo === '' || mobileNo === "0000000000") {
            sessionStorage.setItem('user-mobileno', mobileNo);
        }

        //mainGuestMobileNo = sessionStorage.getItem('main-guest-no');
        mobileNo = sessionStorage.getItem('user-mobileno');

        let postObj = {
            OutletId: propertyId + outletCode,
            GuestMobileCountryCode: "91",
            //GuestMobileNumber: mainGuestMobileNo,
            GuestMobileNumber: mobileNo,
            TableNumber: serviceType !== "FINE-DINE" ? '' : tableNo
        }

        //  console.log( "Cart Page :", postObj);

        fetch(Config.basePOSUrl + 'GetGuestTableStatus', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                // console.log('IscheckedInAPI' , response);
                if (response.errorCode === 0) {

                    if (!response.response.guestTableStatus.isCheckedIn) {
                        this.showMessage("Your selected table has been checked out.", "danger");
                        this.setState({ errorMessage: "Your selected table has been checked out." });
                        // Added by vijay on 04-03-2024 -Due to second guest login time without bill and settle  
                        setTimeout(() => {
                            this.setState({ loading: false });
                            this.props.history.push('/app-error' + urlPattern, { errorMessage: this.state.errorMessage });
                        }, config.averageMsgTimeout);

                        //  console.log("response1",response.guestTableStatus.isCheckedIn);
                        // console.log(response);                                                        

                        //  Commended by vijay due to removed the switch from Operation- Jira id -2075  -Point NO-3
                        // if (olDetail.autoCheckInWhenGuestContinueOrderInSameTable) {


                        //      if(olDetail.checkInWithTablePin )
                        //  {
                        //     sessionStorage.setItem("login-page-session", 3); // Cart Session
                        //     sessionStorage.setItem("on-click-mode", typeId); // Cart Session
                        //     setTimeout(() => {
                        //         this.setState({ loading: false });
                        //         this.props.history.push('/loginverification',  // set valid OTP= false due to show only table pin mode in the loginverification                              
                        //             { mobileNo: mobileNo, validateOTP: false, validatePIN: olDetail.checkInWithTablePin });
                        //             // { mobileNo: mobileNo, validatePIN: validatePIN });
                        //         }, Config.minMsgTimeout);                                                                    
                        //      } 

                        //      else
                        //      {
                        //                 let postObj = {
                        //         OutletId: propertyId + outletCode,
                        //         GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                        //         MobileNo: mobileNo,
                        //         TableNumber: tableNo,
                        //         GroupCode: groupPin
                        //     }
                        //   //  console.log( "GuestCheckIn before",postObj)
                        //     fetch(Config.basePOSUrl + 'GuestCheckIn', {
                        //         method: 'POST',
                        //         headers: {
                        //             Authorization: "Bearer " + token,
                        //             'content-type': 'application/json'
                        //         },
                        //         body: JSON.stringify(postObj)
                        //     })
                        //         .then(res => res.json())
                        //         .then(response => {
                        //             console.log("GuestCheckIn API response" ,response);
                        //             if (response.errorCode === 0) {                                       
                        //                 sessionStorage.setItem('main-guest-no', mobileNo);
                        //                 mainGuestMobileNo = sessionStorage.getItem('main-guest-no');
                        //                 // group pin commented by daspraksh on 04-10-2023 jira-1490
                        //                 // sessionStorage.setItem('group-pin', response.response.groupCode);
                        //                 // group pin added by daspraksh on 04-10-2023 jira-1490
                        //                 sessionStorage.setItem('group-pin', response.response !== null ? response.response.groupCode : "");
                        //                 groupPin = sessionStorage.getItem('group-pin');

                        //                 sessionStorage.setItem('main-guest-name', sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"));
                        //                 sessionStorage.setItem('group-session', false);

                        //                 sessionStorage.setItem('main-guest-mask-no', '');
                        //                 sessionStorage.setItem('ex-main-guest-no', mobileNo);

                        //                 setTimeout(() => {
                        //                     this.handleValidateOrder(typeId, true);
                        //                 }, Config.minMsgTimeout);
                        //             }
                        //             else {
                        //                 // added by dasprakash on 21-10-2023 jira-1644
                        //                 this.setState({ errorMessage: "Your selected table has been checked out." })
                        //                 //this.showMessage("Your selected table has been checked out.", "danger");
                        //                 setTimeout(() => {
                        //                     this.setState({ loading: false });
                        //                     this.props.history.push('/logout');
                        //                 }, Config.minMsgTimeout);
                        //             }
                        //         })
                        //         .catch(err => {
                        //             sessionStorage.removeItem("user-token");
                        //             sessionStorage.removeItem("user-mobileno");
                        //             // added by dasprakash on 21-10-2023 jira-1644
                        //             this.setState({ errorMessage: 'Guest Check IN  - Failed to fetch - ' + err })
                        //             //this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
                        //             //console.log(err)
                        //         });                                

                        //     }
                        // }
                        // else {
                        //     // added by dasprakash on 21-10-2023 jira-1644
                        //     this.setState({ errorMessage: 'Your table has been Closed! , Please scan and login once again' })
                        //     //this.showMessage('Your table has been Closed! , Please scan and login once again', "danger");
                        //     this.setState({ loading: true });
                        //     setTimeout(() => {
                        //         this.setState({ loading: false });
                        //         this.props.history.push('/');
                        //     }, 1000);

                        // }

                        // Added by vijay on 25-01-2024 -Testing Points -Point NO-3

                        // if (olDetail.checkInWithTablePin) {
                        //     sessionStorage.setItem("login-page-session", 3); // Cart Session
                        //     sessionStorage.setItem("on-click-mode", typeId); // Cart Session
                        //     setTimeout(() => {
                        //         this.setState({ loading: false });
                        //         this.props.history.push('/loginverification',  // set valid OTP= false due to show only table pin mode in the loginverification                              
                        //             { mobileNo: mobileNo, validateOTP: false, validatePIN: olDetail.checkInWithTablePin });
                        //         // { mobileNo: mobileNo, validatePIN: validatePIN });  // Added by vijay Testing Points
                        //     }, Config.minMsgTimeout);
                        // }

                        // else {
                        //     let postObj = {
                        //         OutletId: propertyId + outletCode,
                        //         GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                        //         MobileNo: mobileNo,
                        //         TableNumber: tableNo,
                        //         GroupCode: groupPin
                        //     }
                        //     //  console.log( "GuestCheckIn before",postObj)
                        //     fetch(Config.basePOSUrl + 'GuestCheckIn', {
                        //         method: 'POST',
                        //         headers: {
                        //             Authorization: "Bearer " + token,
                        //             'content-type': 'application/json'
                        //         },
                        //         body: JSON.stringify(postObj)
                        //     })
                        //         .then(res => res.json())
                        //         .then(response => {
                        //             console.log("GuestCheckIn API response", response);
                        //             if (response.errorCode === 0) {
                        //                 sessionStorage.setItem('main-guest-no', mobileNo);
                        //                 mainGuestMobileNo = sessionStorage.getItem('main-guest-no');
                        //                 // group pin commented by daspraksh on 04-10-2023 jira-1490
                        //                 // sessionStorage.setItem('group-pin', response.response.groupCode);
                        //                 // group pin added by daspraksh on 04-10-2023 jira-1490
                        //                 sessionStorage.setItem('group-pin', response.response !== null ? response.response.groupCode : "");
                        //                 groupPin = sessionStorage.getItem('group-pin');

                        //                 sessionStorage.setItem('main-guest-name', sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"));
                        //                 sessionStorage.setItem('group-session', false);

                        //                 sessionStorage.setItem('main-guest-mask-no', '');
                        //                 sessionStorage.setItem('ex-main-guest-no', mobileNo);

                        //                 setTimeout(() => {
                        //                     this.handleValidateOrder(typeId, true);
                        //                 }, Config.minMsgTimeout);
                        //             }
                        //             else {
                        //                 // added by dasprakash on 21-10-2023 jira-1644
                        //                 this.setState({ errorMessage: "Your selected table has been checked out." })
                        //                 //this.showMessage("Your selected table has been checked out.", "danger");
                        //                 setTimeout(() => {
                        //                     this.setState({ loading: false });
                        //                     this.props.history.push('/logout');
                        //                 }, Config.minMsgTimeout);
                        //             }
                        //         })
                        //         .catch(err => {
                        //             sessionStorage.removeItem("user-token");
                        //             sessionStorage.removeItem("user-mobileno");
                        //             // added by dasprakash on 21-10-2023 jira-1644
                        //             this.setState({ errorMessage: 'Guest Check IN  - Failed to fetch - ' + err })
                        //             //this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
                        //             //console.log(err)
                        //         });

                        // }

                    }
                    else { // check in true
                        // if multi guest try to login with group order mode -> one guest get forward another guest try again and proceed.

                        if (mobileNo !== mainGuestMobileNo) { //if not main guest
                            sessionStorage.setItem('main-guest-mask-no', response.response.guestTableStatus.maskMobileNo);
                            sessionStorage.setItem('main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                            sessionStorage.setItem('main-guest-name', response.response.guestTableStatus.mainGuestName);
                            sessionStorage.setItem('ex-main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                            mainGuestMobileNo = response.response.guestTableStatus.mainGuestMobileNo;
                        }
                        if (tableNo !== response.response.guestTableStatus.currentTableNumber) {
                            // added by dasprakash on 21-10-2023 jira-1644
                            this.setState({ errorMessage: "Your table has been transfer from " + tableNo + " to " + response.response.guestTableStatus.currentTableNumber })
                            //this.showMessage("Your table has been transfer from " + tableNo + " to " + response.response.guestTableStatus.currentTableNumber, "danger");
                            sessionStorage.setItem('user-tableno', response.response.guestTableStatus.currentTableNumber);
                            tableNo = sessionStorage.getItem('user-tableno');
                        }
                        setTimeout(() => {
                            this.handleValidateOrder(typeId, true);
                        }, Config.minMsgTimeout);
                    }
                }

                else {
                    this.setState({ loading: false });
                    // added by dasprakash on 21-10-2023 jira-1644
                    this.setState({ errorMessage: response.message })
                    //this.showMessage(response.message, "danger");

                }
            })
            .catch(err => {
                this.setState({ loading: false });
                // added by dasprakash on 21-10-2023 jira-1644
                this.setState({ errorMessage: err })
                //this.showMessage(err, "danger");
            });
    }

    handleValidateOrder(typeId, tableCheck) {

        /// user Session Validation
        if (!sHelpers.userSessionValidation(userSessionId)) {
            this.showMessage("Your session has been expired, please try again", "danger");
            setTimeout(() => {
                this.props.history.push('/home' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }

        //console.log("handleValidateOrder")
        let IsAddressChange = false;
        let addressObj = null;
        // Special Instruction Saved
        sessionStorage.setItem("order-instruction", this.state.orderInstruction !== '' ? this.state.orderInstruction : sessionStorage.getItem('order-instruction') !== null ? sessionStorage.getItem('order-instruction') : '');
        orderChargeList = sessionStorage.getItem("order-charge-List") === null ? [] : JSON.parse(sessionStorage.getItem("order-charge-List"));

        if (guestLoggedIn === "false") {
            this.setState({ loading: true });
            sessionStorage.setItem("login-page-session", 3); // Cart Session
            sessionStorage.setItem("on-click-mode", typeId); // Cart Session
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/login' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
        else {
            sessionStorage.removeItem("login-page-session"); // Cart Session
            sessionStorage.removeItem("on-click-mode"); // Cart Session
        }

        // Delivery Mode
        if (serviceType === Config.serviceType.DELIVERY) {

            if (guestCurrentAddress === null) {
                sessionStorage.setItem('user-addressId', 0);
                // added by dasprakash on 21-10-2023 jira-1644
                this.setState({ errorMessage: 'Address not found, Please change the valid address' })
                //this.showMessage('Address not found, Please change the valid address', "danger");
                IsAddressChange = true;
            }
            else {
                sessionStorage.setItem('user-addressId', parseInt(guestCurrentAddress.addressId));
                if (guestCurrentAddress.addressLine1.trim() === '') {
                    if (guestCurrentAddress.addressLine2.trim() === '') {
                        if (guestCurrentAddress.addressLine3.trim() === '') {
                            // added by dasprakash on 21-10-2023 jira-1644
                            this.setState({ errorMessage: 'Address not found, Please change the valid address' })
                            //this.showMessage('Address not found, Please change the valid address', "danger");
                            IsAddressChange = true;
                        }
                    }
                }

                if (guestCurrentAddress.zipCode.trim() === '') {
                    // added by dasprakash on 21-10-2023 jira-1644
                    this.setState({ errorMessage: 'Invalid zipcode, Please change the valid address' })
                    //this.showMessage('Invalid zipcode, Please change the valid address', "danger");
                    IsAddressChange = true;
                }
                if (guestCurrentAddress.city.trim() === '') {
                    // added by dasprakash on 21-10-2023 jira-1644
                    this.setState({ errorMessage: 'Invalid city name, Please change the valid address' })
                    //this.showMessage('Invalid city name, Please change the valid address', "danger");
                    IsAddressChange = true;
                }
                if (guestCurrentAddress.area.trim() === '') {
                    // added by dasprakash on 21-10-2023 jira-1644
                    this.setState({ errorMessage: 'Invalid area name, Please change the valid address' })
                    //this.showMessage('Invalid area name, Please change the valid address', "danger");
                    IsAddressChange = true;
                }
            }

            if (IsAddressChange) {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/chooseaddress' + urlPattern);
                }, Config.minMsgTimeout);
                return false;
            }

            // Get Address
            addressObj = {
                AddressId: guestCurrentAddress.addressId,
                AddressLine1: guestCurrentAddress.addressLine1,
                AddressLine2: guestCurrentAddress.addressLine2,
                AddressLine3: guestCurrentAddress.addressLine3,
                Area: guestCurrentAddress.area,
                City: guestCurrentAddress.city,
                State: guestCurrentAddress.addressId,
                Landmark: guestCurrentAddress.landmark,
                ZipCode: guestCurrentAddress.zipCode,
                AddressType: guestCurrentAddress.addressType
            }
        }

        // Guest Table Check - CheckIn / Check Out / Table Transfer - to Get latest Table Detail
        if (serviceType === "FINE-DINE" && tableCheck === false) {
            this.guestTableCheck(typeId);
            return false;
        }
        this.setState({ loading: true });

        let postItemList = [];
        Array.prototype.forEach.call(cartList.itemList, itm => {
            let postTaxes = [];
            let postModifiers = [];
            let postCharges = [];
            vailduniquesGroups = [];

            // tax
            let ctax = taxList.filter(x => x.uid === itm.uid);
            ctax.map(tx =>
                postTaxes.push({ TaxCode: tx.taxCode, TaxValueType: tx.taxType, TaxValue: tx.taxValue, TaxAmount: tx.taxAmount })
            )

            //modifier
            let cmodifer = modifierCartList.filter(x => x.uid === itm.uid);
            // //console.log(cmodifer);
            var _taxItmFound = {};
            for (var md = 0, lenc = cmodifer.length; md <= lenc - 1; md++) {
                var stringified = JSON.stringify(cmodifer[md].modifierGroupId);
                if (_taxItmFound[stringified]) { continue; }
                vailduniquesGroups.push({ groupId: cmodifer[md].modifierGroupId, groupName: cmodifer[md].modifierGroupName });
                _taxItmFound[stringified] = true;
            };
            // //console.log(vailduniquesGroups);
            // Modifier
            Array.prototype.forEach.call(vailduniquesGroups, mdg => {
                var modifr = [];
                let mdlist = cmodifer.filter(x => x.modifierGroupId === mdg.groupId);
                Array.prototype.forEach.call(mdlist, md => {
                    let modifiertax = [];
                    let modifierCharge = [];

                    //tax
                    Array.prototype.forEach.call(md.taxes, x => {
                        modifiertax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
                    });

                    //charge
                    Array.prototype.forEach.call(md.charges, chg => {
                        let chargetax = [];

                        //tax
                        Array.prototype.forEach.call(chg.taxes, x => {
                            chargetax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
                        })

                        // IsMerchantCharge is hardcorded
                        modifierCharge.push({
                            ChargeId: chg.chargeId, ChargeName: chg.chargeName,
                            ChargeType: chg.chargeType, IsMerchantCharge: false, ChargeValueType: chg.chargeValueType,
                            ChargeValue: chg.chargeValue, ChargeAmount: chg.chargeAmount,
                            Taxes: chargetax
                        })
                    });

                    modifr.push({ ModfierId: md.modifierId, ModifierName: md.modifierName, Rate: md.rate, Quantity: itm.qty, Amount: md.rate * itm.qty, Taxes: modifiertax, Charges: modifierCharge })
                })
                postModifiers.push({ ModifierGroupId: mdg.groupId, GroupName: mdg.groupName, Modifiers: modifr });
            });
            // //console.log(postModifiers);

            // charges
            let ccharge = chargeList.filter(x => x.uid === itm.uid);

            Array.prototype.forEach.call(ccharge, chg => {
                let chargetax = [];

                //tax
                Array.prototype.forEach.call(chg.taxes, x => {
                    chargetax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
                })

                // IsMerchantCharge is hardcorded
                postCharges.push({
                    ChargeId: chg.chargeId, ChargeName: chg.chargeName,
                    ChargeType: chg.chargeType, IsMerchantCharge: false, ChargeValueType: chg.chargeValueType,
                    ChargeValue: chg.chargeValue, ChargeAmount: chg.chargeAmount,
                    Taxes: chargetax
                })
            });
            // //console.log(postCharges);

            postItemList.push({
                ItemId: itm.itemId,
                ItemName: itm.itemName, Rate: itm.rate,
                Quantity: itm.qty, Amount: (itm.qty * itm.rate),
                Instruction: itm.specInsc === undefined ? "" : itm.specInsc,
                Taxes: postTaxes,
                ModifierGroups: postModifiers,
                Charges: postCharges
            });

        });
        let orderdate = new Date();
        let mseconds = orderdate.getTime() //1440516958
        let deliveryTime = orderdate.getTime() + (olDetail.minimumDeliveryTime * 60000); //1440516958
        // //console.log(mseconds);

        //  this.handleCartRecalculate();

        // paymentDetails.push({
        //     PayMode: "ONLP", // Online Pay
        //     PaymentAmount: this.state.totalNetAmount.toFixed(2),
        //     TransactionRefId: '',
        //     PaymentVendorId: '', // Vendor Id
        //     PaymentVendorName: ''
        // });
        //this.setState({vendorOrderId: uuid()});
        //  sessionStorage.setItem('user-vendorOrderId', uuid());
        ///  Get QR Location
        let specialInstu = '';

        if (olDetail.orderInstructionIncludeQRCodeLocation === true) {
            // //console.log(olDetail.orderInstructionIncludeQRCodeLocation);
            specialInstu = sessionStorage.getItem('url-remarks') === null ? '' : sessionStorage.getItem('url-remarks') !== '' ? sessionStorage.getItem('url-remarks') : '';
            // //console.log(specialInstu);
        }

        /// Order Charges
        let orderCharges = [];
        Array.prototype.forEach.call(orderChargeList, ochg => {
            let chargetax = [];
            //tax
            Array.prototype.forEach.call(ochg.taxes, x => {
                chargetax.push({ TaxCode: x.taxCode, TaxValueType: x.taxType, TaxValue: x.taxValue, TaxAmount: x.taxAmount })
            })

            // IsMerchantCharge is hardcorded
            orderCharges.push({
                ChargeId: ochg.chargeId, ChargeName: ochg.chargeName,
                ChargeType: ochg.chargeType, IsMerchantCharge: false, ChargeValueType: ochg.chargeValueType,
                ChargeValue: ochg.chargeValue, ChargeAmount: ochg.chargeAmount,
                Taxes: chargetax
            });
        });

        // //console.log(orderCharges);
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no');
        let postObj = {
            OutletId: propertyId + outletCode,
            ServiceType: serviceType,
            GuestDetails: {
                // group pin commented by dasprakash on 11-10-2023 jira-1490
                // MobileNo: mainGuestMobileNo,
                // group pin added by dasprakash on 11-10-2023 jira-1490
                // MobileNo: mobileNo,
                // MobileNo: mainGuestMobileNo === "" ? mobileNo : mainGuestMobileNo,
                MobileNo: (mainGuestMobileNo === '' || mainGuestMobileNo === "0000000000") ? mobileNo : mainGuestMobileNo,
                OrderedGuestMobileNo: mobileNo,
                OrderedGuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"), GuestName: sessionStorage.getItem("main-guest-name") === null ? (sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name")) : sessionStorage.getItem("main-guest-name"),
                Address: addressObj === null ? {} : addressObj
            },
            OrderDetails: {
                VendorOrderId: sessionStorage.getItem('user-vendorOrderId'),
                OrderDateTime: mseconds,
                DeliveryDateTime: serviceType === Config.serviceType.DELIVERY ? deliveryTime : null,
                TableNumber: serviceType !== "FINE-DINE" ? '' : tableNo,
                OrderStatus: "placed",
                // Instruction: (specialInstu !== '' ? specialInstu + ' - ' : '') + (this.state.orderInstruction === null ? '' : this.state.orderInstruction),
                Instruction: (specialInstu !== '' ? specialInstu : '') + (this.state.orderInstruction === null ? '' : (specialInstu !== '' && this.state.orderInstruction !== '') ? ' - ' + this.state.orderInstruction : this.state.orderInstruction),
                Items: postItemList,
                // TotalItemValue: this.state.totalItemAmount.toFixed(2),
                // TotalItemTaxes: this.state.totaltaxAmount.toFixed(2),
                // TotalItemCharges: this.state.totalChargesAmount.toFixed(2),
                TotalTaxes: this.state.totaltaxAmount.toFixed(2),
                TotalCharges: totalChargesAmount,
                TotalAmount: this.state.totalNetAmount.toFixed(2),
                IsPaid: false,
                Charges: orderCharges
            },
            PaymentDetails: []
        }


        // console.log("GuestDetails" , GuestDetails);      
        //console.log(JSON.stringify(postObj));
        // //console.log(Config.basePOSUrl + 'ValidateOrder');

        fetch(Config.basePOSUrl + 'ValidateOrder', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                //  console.log(postObj);
                if (response.errorCode === 0) {
                    if (typeId === 1) // Place Order
                    {
                        fetch(Config.basePOSUrl + 'PlaceOrder', {
                            method: 'POST',
                            headers: {
                                Authorization: "Bearer " + token,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify(postObj)
                        })
                            .then(res => res.json())
                            .then(response => {
                                //console.log(new Date());

                                //   console.log("response",response);
                                if (response.errorCode === 0) {
                                    this.setState({ loading: true });
                                    setTimeout(() => {
                                        // this.setState({ loading: false });
                                        sessionStorage.removeItem("paymentInitiatedId");
                                        sessionStorage.removeItem("vendorTransactionId");
                                        sessionStorage.removeItem("vendorId");
                                        sessionStorage.removeItem("vendorName");
                                        sessionStorage.removeItem("paymentStatus");
                                        sessionStorage.removeItem("order-instruction");
                                        sessionStorage.removeItem("user-vendorOrderId");
                                        //console.log(new Date());

                                        this.props.history.push('/ordersuccess' + urlPattern,
                                            {
                                                // orderId: response.response.orderId
                                                orderId: postObj.OrderDetails.VendorOrderId,
                                                orderNo: response.response != null ? response.response.orderNo : ''
                                            });
                                    }, 200);
                                }
                                else {
                                    //  //console.log(response.message);
                                    this.setState({ loading: true });
                                    // added by dasprakash on 21-10-2023 jira-1644
                                    this.setState({ errorMessage: 'Error Order was not placed' })
                                    //this.showMessage('Error Order was not placed', "danger");
                                    //sessionStorage.removeItem("user-vendorOrderId");
                                }
                            })
                            .catch(err => {
                                //   //console.log(err);
                                this.setState({ loading: true });
                                // added by dasprakash on 21-10-2023 jira-1644
                                this.setState({ errorMessage: 'Error Order was not placed' })
                                //this.showMessage('Error Order was not placed', "danger");
                                // sessionStorage.removeItem("user-vendorOrderId");
                            });
                    }
                    else {
                        setTimeout(() => {
                            // this.setState({ loading: false });
                            sessionStorage.setItem("order-instruction", this.state.orderInstruction);
                            this.props.history.push('/payment' + urlPattern, { totalNetAmount: this.state.totalNetAmount, totalItems: this.state.totalItems });
                        }, Config.minMsgTimeout);
                    }
                }
                else if (response.errorCode === 3) {  /// Check in table
                    if (serviceType === "FINE-DINE") { // Table Check In
                        if (olDetail.checkInWithTablePin) {
                            setTimeout(() => {
                                this.setState({ loading: false });
                                this.props.history.push('/tableverification' + urlPattern, { redirectCart: true });
                            }, Config.minMsgTimeout);
                            return false
                        }
                        let postTableObj = {
                            OutletId: propertyId + outletCode,
                            GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                            MobileNo: mobileNo,
                            TableNumber: tableNo,
                            GroupCode: groupPin
                        }
                        fetch(Config.basePOSUrl + 'GuestCheckIn', {
                            method: 'POST',
                            headers: {
                                Authorization: "Bearer " + token,
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify(postTableObj)
                        })
                            .then(res => res.json())
                            .then(response => {
                                //console.log(postTableObj);
                                if (response.errorCode === 0) {
                                    sessionStorage.setItem('user-table-verified', true);
                                    if (typeId === 1) // Place Order
                                    {
                                        fetch(Config.basePOSUrl + 'PlaceOrder', {
                                            method: 'POST',
                                            headers: {
                                                Authorization: "Bearer " + token,
                                                'content-type': 'application/json'
                                            },
                                            body: JSON.stringify(postObj)
                                        })
                                            .then(res => res.json())
                                            .then(response => {
                                                //console.log(new Date());

                                                if (response.errorCode === 0) {
                                                    this.setState({ loading: true });
                                                    setTimeout(() => {
                                                        // this.setState({ loading: false });
                                                        sessionStorage.removeItem("paymentInitiatedId");
                                                        sessionStorage.removeItem("vendorTransactionId");
                                                        sessionStorage.removeItem("vendorId");
                                                        sessionStorage.removeItem("vendorName");
                                                        sessionStorage.removeItem("paymentStatus");
                                                        sessionStorage.removeItem("order-instruction");
                                                        sessionStorage.removeItem("user-vendorOrderId");
                                                        //console.log(new Date());

                                                        this.props.history.push('/ordersuccess' + urlPattern,
                                                            {
                                                                // orderId: response.response.orderId
                                                                orderId: postObj.OrderDetails.VendorOrderId,
                                                                orderNo: response.response.orderNo
                                                            });
                                                    }, 200);
                                                }
                                                else {
                                                    //  //console.log(response.message);
                                                    this.setState({ loading: true });
                                                    // added by dasprakash on 21-10-2023 jira-1644
                                                    this.setState({ errorMessage: 'Error Order was not placed' })
                                                    //this.showMessage('Error Order was not placed', "danger");
                                                    //  sessionStorage.removeItem("user-vendorOrderId");
                                                }
                                            })
                                            .catch(err => {
                                                //   //console.log(err);
                                                this.setState({ loading: true });
                                                // added by dasprakash on 21-10-2023 jira-1644
                                                this.setState({ errorMessage: 'Error Order was not placed' })
                                                //this.showMessage('Error Order was not placed', "danger");
                                                //  sessionStorage.removeItem("user-vendorOrderId");
                                            });
                                    }
                                    else {
                                        setTimeout(() => {
                                            // this.setState({ loading: false });
                                            sessionStorage.setItem("order-instruction", this.state.orderInstruction);
                                            this.props.history.push('/payment' + urlPattern, { totalNetAmount: this.state.totalNetAmount, totalItems: this.state.totalItems });
                                        }, Config.minMsgTimeout);
                                    }
                                }
                                else {
                                    // added by dasprakash on 21-10-2023 jira-1644
                                    this.setState({ errorMessage: response.message })
                                    //this.showMessage(response.message, "danger");
                                    this.setState({ loading: true });
                                    setTimeout(() => {
                                        this.setState({ loading: false });
                                        this.props.history.push('/qrcoderror' + urlPattern);
                                    }, Config.minMsgTimeout);
                                }
                            })
                            .catch(err => {
                                // added by dasprakash on 21-10-2023 jira-1644
                                this.setState({ errorMessage: 'Guest Check IN  - Failed to fetch - ' + err })
                                //this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
                            });

                    } // End Service Type - Fine Dine
                    else {
                        sessionStorage.setItem('user-table-verified', true);
                        setTimeout(() => {
                            this.setState({ loading: false });
                            this.props.history.push('/home' + urlPattern);
                        }, Config.minMsgTimeout);
                    }
                }
                else if (response.errorCode === 4) { // Addded by vijay - duplicate Order handled on 11-10-2024 - jira id -3924
                    this.setState({ loading: true });
                    setTimeout(() => {
                        sessionStorage.removeItem("paymentInitiatedId");
                        sessionStorage.removeItem("vendorTransactionId");
                        sessionStorage.removeItem("vendorId");
                        sessionStorage.removeItem("vendorName");
                        sessionStorage.removeItem("paymentStatus");
                        sessionStorage.removeItem("order-instruction");
                        sessionStorage.removeItem("user-vendorOrderId");
                        //console.log(new Date());

                        this.props.history.push(
                            "/ordersuccess" + urlPattern,
                            {
                                // orderId: response.response.orderId
                                orderId: postObj.OrderDetails.VendorOrderId,
                                orderNo: response.response.orderNo,
                            }
                        );
                    }, 200);
                }
                else {
                    //console.log("response.message");
                    //console.log(response.message);
                    this.setState({ loading: false });
                    // added by dasprakash on 21-10-2023 jira-1644

                    if (response.message === "Restaurant is Closed. You cannot place the Order") {
                        this.handlePopupAlertMessage();
                        this.setState({ alertMessages: " Please call the waiter to place an order, and once placed, the order can't be cancelled." });

                    }
                    else {
                        this.setState({ errorMessage: response.message })
                    }

                }
            })
            .catch(err => {
                //console.log(err.stack);
                this.setState({ loading: false });
                // added by dasprakash on 21-10-2023 jira-1644
                this.setState({ errorMessage: err.message })
                //this.showMessage(err.message, "danger");
            }); // End Validate Order

    };

    handleSpecialInstruction = (itmName, uid, specInsc) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    //   div Style Added by vijay on 30-01-2024 -Special Instruction Alignment -Jira id -2075 -Testing Points
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='special-insc'>
                            <h5>Special Instruction</h5>
                            <span style={{ color: "rgba(24, 164, 55, 0.70)" }} className="itm-name">{itmName}</span>
                            <textarea id={"spec-insc-" + uid} rows="2" cols="30" maxLength="100" placeholder="write here.." autoFocus>{specInsc}</textarea>
                            <button onClick={onClose} className="btn-left">Cancel</button>
                            <button
                                onClick={() => {
                                    this.handleUpdateSpecialInstruction(uid, false);
                                    onClose();
                                }}
                                className="btn-right">
                                Done
                            </button>
                        </div>
                    </div>
                );
            }
        });
    };

    handleUpdateSpecialInstruction(uid, remove) {

        let index = cartList.itemList.findIndex(x => x.uid === uid);
        let specInscVal = '';
        if (index > -1) {
            if (!remove) {
                specInscVal = document.getElementById('spec-insc-' + uid).value;
                if (cartList.itemList[index].specInsc === undefined)
                    cartList.itemList.map(x => Object.assign({}, cartList.itemList, { "specInsc": specInscVal }));
                else
                    cartList.itemList[index].specInsc = specInscVal;
            }

            cartList.itemList[index].specInsc = specInscVal;
            this.setState({ cartItemList: cartList.itemList });
            sessionStorage.setItem("cartList", JSON.stringify(cartList));
        }

    };

    gotoAddressChoose(addressId) {
        // Special Instruction Saved
        sessionStorage.setItem("order-instruction", this.state.orderInstruction !== '' ? this.state.orderInstruction : sessionStorage.getItem('order-instruction') !== null ? sessionStorage.getItem('order-instruction') : '');

        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            sessionStorage.setItem('user-addressId', addressId)
            this.props.history.push('/chooseaddress' + urlPattern);
        }, Config.minMsgTimeout);
    };

    handleChooseAddrss() {
        // Special Instruction Saved
        sessionStorage.setItem("order-instruction", this.state.orderInstruction !== '' ? this.state.orderInstruction : sessionStorage.getItem('order-instruction') !== null ? sessionStorage.getItem('order-instruction') : '');

        if (guestLoggedIn === "false") {
            this.setState({ loading: true });
            sessionStorage.setItem("login-page-session", 9); // Address Session
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/login' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
        this.gotoAddressChoose(0);
    };

    showMessage(msg, type) {

        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };

    // componentDidCatch(error, info) {
    //     // Display fallback UI
    //     this.setState({ hasError: true });
    //     //this.showMessage(error, "danger");
    //     //console.log(error)
    //     // You can also log the error to an error reporting service
    //     //logErrorToMyService(error, info);
    // }

    render() {
        const { contentMessage } = this.state;
        const errorMessageHeight = contentMessage.length === 0 ? '55px' : '110px';
        // totaltaxAmount = this.state.cartItemList.length > 0 ? taxList.reduce((prev, next) => prev + next.taxAmount, 0) : 0;
        // totaltaxAmount += this.state.cartItemList.length > 0 ? chargeList.reduce((prev, next) => prev + next.totalTaxAmount, 0) : 0;
        // totaltaxAmount += this.state.cartItemList.length > 0 ? modifierCartList.reduce((prev, next) => prev + next.totalTaxAmount, 0) : 0;
        // totalChargesAmount = this.state.cartItemList.length > 0 ? chargeList.reduce((prev, next) => prev + next.chargeAmount, 0) : 0;
        // totalChargesAmount += this.state.cartItemList.length > 0 ? modifierCartList.reduce((prev, next) => prev + next.totalChargeAmount, 0) : 0;
        // totalAmount = totalItemAmount + totaltaxAmount + totalChargesAmount;

        return (

            <div>
                {this.state.loading && <LoadingAnimation />}
                <div style={{ backgroundColor: "#F0F0F0", paddingBottom: "10px" }} id="content" className="top">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <div className="content-warp">
                        <div className="section-cart">
                            <div className="heading-page" ref="recommended">
                                {/* commented by dasprakash on 19-10-2023 <jira-1574></jira-1574> */}
                                <p style={{ fontSize: "16px", fontWeight: "500", textAlign: "center", color: "#000" }}>Checkout</p>

                                <div className="back-btn">
                                    {/* commented by dasprakash on 19-10-2023 <jira-1574></jira-1574> */}
                                    {/* <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left" ></i></div> */}
                                    <svg onClick={this.goBack} style={{ marginTop: "15px" }} xmlns="http://www.w3.org/2000/svg" width="11" height="22" viewBox="0 0 13 22" fill="none">
                                        <path d="M12 21L2 11L12 1" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </div>
                            </div>
                            {/* style modified by vijay on 31-10-2023 */}
                            <div style={{ paddingTop: "1px" }} className="content-from">
                                <div className="itm-heading">
                                    <span style={{ fontSize: "16px" }}>{sessionStorage.getItem("user-propertyname")}</span>
                                    <span style={{ fontSize: "13px", fontWeight: "500" }} className="city-txt">{sessionStorage.getItem("user-outletname")} - {sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")}</span>
                                </div>

                                {serviceType === Config.serviceType.DELIVERY &&
                                    <>
                                        {(guestLoggedIn === "true" && guestCurrentAddress !== null) &&
                                            <div className="content delivery">
                                                <div className="addr-heading">Delivery To <span className="addr-type">{guestCurrentAddress.addressType}</span></div>
                                                <button className="theme-button btn change-btn" onClick={this.gotoAddressChoose.bind(this, guestCurrentAddress.addressId)}>Change</button>
                                                <div className="address">
                                                    {guestName === '' && guestDetails.guestName}
                                                    {guestName !== '' && guestName}
                                                    <br />
                                                    {guestCurrentAddress.addressLine1} {guestCurrentAddress.addressLine2} {guestCurrentAddress.addressLine3}
                                                    <br />
                                                    {guestCurrentAddress.area}
                                                    <br />
                                                    {guestCurrentAddress.city} - {guestCurrentAddress.zipCode}<br />
                                                    {guestCurrentAddress.landmark.trim() !== '' && 'Landmark: ' + guestCurrentAddress.landmark}
                                                </div>
                                            </div>
                                        }
                                        {(guestLoggedIn === "false") &&
                                            <div className="content delivery">
                                                <div className="not-login-msg">
                                                    {/* commented by dasprakash on 19-10-2023 jira-1574 */}
                                                    {/* We couldn't find your address. Please login and continue. <span className="login" onClick={this.handleChooseAddrss.bind(this)}>Login</span> */}
                                                    Add Address . Please Login to Continue. <span className="login" onClick={this.handleChooseAddrss.bind(this)}>Login</span>
                                                    {/* <button className="theme-button btn choose-btn" onClick={this.handleChooseAddrss.bind(this)}>Login</button> */}
                                                </div>
                                            </div>
                                        }
                                        {(guestLoggedIn === "true" && guestCurrentAddress === null) &&
                                            <div className="content delivery">
                                                <div className="not-login-msg">
                                                    <span className="login" onClick={this.handleChooseAddrss.bind(this)}>Choose Address</span>
                                                    {/* <button className="theme-button btn choose-btn" onClick={this.handleChooseAddrss.bind(this)}>Login</button> */}
                                                </div>

                                            </div>
                                        }
                                    </>
                                }

                                <div style={{ backgroundColor: "#FFF", borderRadius: "10px", margin: "15px", padding: "8px" }} className="wrap-list-rc regular-item" id="item-list">
                                    {
                                        this.state.cartItemList.map(itm =>

                                            <div className="lrc-item" key={"mnu-cart-" + itm.groupId + "-" + itm.itemId + "-" + itm.uid}>
                                                <div className="lrc-content">
                                                    <div className="lrc-desc">
                                                        {/* <span className="itm-delete" onClick={this.handleDetletItem.bind(this,itm.id,itm.rate,itm.qty)}><i className="fas fa-trash-alt"></i></span> */}
                                                        <span
                                                            // style added by dasprakash on 25-10-2023 jira-1645
                                                            style={{
                                                                marginRight: itm.rate.toFixed(2).length > 6 ?
                                                                    (window.innerWidth >= 430 ? "15px " : "39px") : ""
                                                            }}
                                                            className="price-detail">
                                                            <Currency />{itm.rate.toFixed(2)}
                                                        </span>
                                                        <div
                                                            // style added by dasprakash on 25-10-2023 jira-1645
                                                            style={{
                                                                marginRight: itm.rate.toFixed(2).length > 6
                                                                    ? (window.innerWidth >= 430 ? "14px" : "5px")
                                                                    : ""
                                                            }}
                                                            className="qty-detail">
                                                            <div className="">
                                                                <span className="minus" onClick={this.handleQtyIncDec.bind(this, itm.id, 0, itm.rate, itm.itemName, itm.itemId, itm.groupId, itm.foodType, itm.isModifier, itm.uid, itm.taxId, itm.chargeIds)}>
                                                                    {/* <i className="fa fa-minus" aria-hidden="true"></i> */}
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <mask id="path-1-outside-1_5691_2445" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20" fill="black">
                                                                            <rect fill="white" width="20" height="20" />
                                                                            <path fillRule="evenodd" clip-rule="evenodd" d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM5 11H15V9H5V11Z" />
                                                                        </mask>
                                                                        <path fillRule="evenodd" clip-rule="evenodd" d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM5 11H15V9H5V11Z" fill="white" fill-opacity="0.78" />
                                                                        <path d="M15 11V12H16V11H15ZM5 11H4V12H5V11ZM15 9H16V8H15V9ZM5 9V8H4V9H5ZM10 20C15.5228 20 20 15.5228 20 10H18C18 14.4183 14.4183 18 10 18V20ZM0 10C0 15.5228 4.47715 20 10 20V18C5.58172 18 2 14.4183 2 10H0ZM10 0C4.47715 0 0 4.47715 0 10H2C2 5.58172 5.58172 2 10 2V0ZM20 10C20 4.47715 15.5228 0 10 0V2C14.4183 2 18 5.58172 18 10H20ZM15 10H5V12H15V10ZM14 9V11H16V9H14ZM5 10H15V8H5V10ZM6 11V9H4V11H6Z" fill="#18A437" fill-opacity="0.78" mask="url(#path-1-outside-1_5691_2445)" />
                                                                    </svg>

                                                                </span>
                                                                {/* {itm.isModifier === 1 && <span className="minus" onClick={this.handleQtyIncDec.bind(this, itm.id, 0, itm.rate, itm.itemName, itm.itemId, itm.groupId, itm.foodType, itm.isModifier, itm.uid)}><i className="fa fa-minus" aria-hidden="true"></i></span>} */}
                                                                <span className="qty" id={itm.uid}>{itm.qty}</span>
                                                                <span className="plus" onClick={this.handleQtyIncDec.bind(this, itm.id, 1, itm.rate, itm.itemName, itm.itemId, itm.groupId, itm.foodType, itm.isModifier, itm.uid, itm.taxId, itm.chargeIds)}>
                                                                    {/* <i className="fa fa-plus" aria-hidden="true"></i> */}
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18" fill="none">
                                                                        <mask id="path-1-inside-1_5691_2449" fill="white">
                                                                            <path fillRule="evenodd" clip-rule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9 15C8.44771 15 8 14.5523 8 14V10H4C3.44772 10 3 9.55229 3 9C3 8.44771 3.44772 8 4 8H8V4C8 3.44772 8.44771 3 9 3C9.55228 3 10 3.44772 10 4V8H14C14.5523 8 15 8.44771 15 9C15 9.55229 14.5523 10 14 10H10V14C10 14.5523 9.55229 15 9 15Z" />
                                                                        </mask>
                                                                        <path fillRule="evenodd" clip-rule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9 15C8.44771 15 8 14.5523 8 14V10H4C3.44772 10 3 9.55229 3 9C3 8.44771 3.44772 8 4 8H8V4C8 3.44772 8.44771 3 9 3C9.55228 3 10 3.44772 10 4V8H14C14.5523 8 15 8.44771 15 9C15 9.55229 14.5523 10 14 10H10V14C10 14.5523 9.55229 15 9 15Z" fill="#18A437" />
                                                                        <path d="M8 10H9V9H8V10ZM8 8V9H9V8H8ZM10 8H9V9H10V8ZM10 10V9H9V10H10ZM9 19C14.5228 19 19 14.5228 19 9H17C17 13.4183 13.4183 17 9 17V19ZM-1 9C-1 14.5228 3.47715 19 9 19V17C4.58172 17 1 13.4183 1 9H-1ZM9 -1C3.47715 -1 -1 3.47715 -1 9H1C1 4.58172 4.58172 1 9 1V-1ZM19 9C19 3.47715 14.5228 -1 9 -1V1C13.4183 1 17 4.58172 17 9H19ZM7 14C7 15.1046 7.89543 16 9 16V14H7ZM7 10V14H9V10H7ZM8 9H4V11H8V9ZM4 9H2C2 10.1046 2.89543 11 4 11V9ZM4 9V7C2.89543 7 2 7.89543 2 9H4ZM4 9H8V7H4V9ZM7 4V8H9V4H7ZM9 2C7.89543 2 7 2.89543 7 4H9V4V2ZM11 4C11 2.89543 10.1046 2 9 2V4V4H11ZM11 8V4H9V8H11ZM10 9H14V7H10V9ZM14 9H16C16 7.89543 15.1046 7 14 7V9ZM14 9V11C15.1046 11 16 10.1046 16 9H14ZM14 9H10V11H14V9ZM11 14V10H9V14H11ZM9 16C10.1046 16 11 15.1046 11 14H9V16Z" fill="#18A437" mask="url(#path-1-inside-1_5691_2449)" />
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                            <div>
                                                                {/* <span style={{ color: "#28a745", fontSize: "14px", fontWeight: "600" }}>ADD</span> */}
                                                            </div>
                                                        </div>
                                                        <div className="lrc-title">
                                                            {/* commented by dasprakash on 19-10-2023 jira-1645 */}
                                                            {/* {itm.foodType === 1 && <img className="food-type-img" src={"../assets/theme1/img/f-t-veg.png"} alt="item" />}
                                                            {itm.foodType === 2 && <img className="food-type-img" src={"../assets/theme1/img/f-t-nonveg.png"} alt="item" />} */}
                                                            {/* added by dasprakash on 19-10-2023 jira-1645 */}
                                                            <p style={{ fontSize: "16px ", fontWeight: "500", color: "#000" }}> {itm.itemName.toLowerCase()}</p>

                                                        </div>

                                                        {/* <div className="qty-detail">
                                                        <span className="minus"><i className="fa fa-minus" aria-hidden="true"></i></span> <span className="qty">10</span> <span className="plus"><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                    </div> */}

                                                        {/* <div className="itm-modifier">
                                                        <span>Dark Chocolate Chunk</span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div key={"mnu-cart-modifier-" + itm.groupId + "-" + itm.itemId + "-" + itm.uid}>
                                                    {itm.isModifier === 1 &&
                                                        this.state.modifierItemList.filter(x => x.uid === itm.uid).map((mitm, idx) =>
                                                            <div key={"md-item-" + mitm.modifierId + "-" + itm.itemId + "-" + itm.uid + "-" + idx}>
                                                                <div className="lrc-item modifier-item" key={"mnu-cart-modifier-" + mitm.modifierId + "-" + itm.itemId + "-" + itm.uid}>
                                                                    <div className="lrc-content">
                                                                        <div className="lrc-desc">
                                                                            <span className="price-detail modifier-price"><Currency />{mitm.rate.toFixed(2)}</span>

                                                                            <div className="lrc-title">
                                                                                {/* commented by dasprakash on 19-10-2023 jira-1645 */}
                                                                                {/* {mitm.foodType === 1 && <img className="food-type-img" src={"../assets/theme1/img/f-t-veg.png"} alt="item" />}
                                                                                {mitm.foodType === 2 && <img className="food-type-img" src={"../assets/theme1/img/f-t-nonveg.png"} alt="item" />} */}
                                                                                {/* added by dasprakash on 19-10-2023 jira-1645 */}
                                                                                <p style={{ fontSize: "14px", fontWeight: "400", color: "#6F7072" }}> {mitm.modifierName}</p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {(olDetail.enableSpecialInstruction) &&
                                                        <div>
                                                            {(itm.specInsc === undefined || itm.specInsc === "") &&
                                                                <div className="spcecial-instc" id={"add-insc-" + itm.uid} onClick={this.handleSpecialInstruction.bind(this, itm.itemName.toLowerCase(), itm.uid, "")}>
                                                                    Add Special Instruction
                                                                </div>
                                                            }
                                                            {(itm.specInsc !== "" && itm.specInsc !== undefined) &&
                                                                <div className="special-insc-main">
                                                                    <div className="special-insc-desc">
                                                                        {/* //commented by dasprakash on 20-10-2023 jira-1645 */}
                                                                        {/* <span onClick={this.handleSpecialInstruction.bind(this, itm.itemName.toLowerCase(), itm.uid, itm.specInsc)}>{itm.specInsc}</span> */}
                                                                        <div>
                                                                            {/* added by dasprakash on 21-10-2023 jira-1644 */}
                                                                            <span onClick={this.handleSpecialInstruction.bind(this, itm.itemName.toLowerCase(), itm.uid, itm.specInsc)} className="spcecial-instc">{itm.specInsc.length > 30 ? itm.specInsc.slice(0, 30) + '...' : itm.specInsc}</span>

                                                                        </div>
                                                                        <div>
                                                                            {/* //added by dasprakash on 20-10-2023 jira-1645 */}
                                                                            {/* <svg style={{ marginLeft: "10px" }} onClick={this.handleUpdateSpecialInstruction.bind(this, itm.uid, true)} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                                                <path d="M1 14L14 0.999999" stroke="#FF0000" strokeWidth="2" strokeLinecap="round" />
                                                                                <path d="M14 14L1 1" stroke="#FF0000" strokeWidth="2" strokeLinecap="round" />
                                                                            </svg> */}
                                                                            {/* // Modified by vijay on 04-11-2023 */}
                                                                            <svg style={{ marginLeft: "10px" }} onClick={this.handleUpdateSpecialInstruction.bind(this, itm.uid, true)} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM4.36504 13.6276C4.01357 13.2761 4.01357 12.7062 4.36504 12.3548L7.72721 8.9926L4.36217 5.62756C4.0107 5.27609 4.0107 4.70624 4.36217 4.35477C4.71364 4.0033 5.28349 4.0033 5.63496 4.35477L9 7.71981L12.365 4.35477C12.7165 4.0033 13.2864 4.0033 13.6378 4.35477C13.9893 4.70624 13.9893 5.27609 13.6378 5.62756L10.2728 8.9926L13.635 12.3548C13.9864 12.7062 13.9864 13.2761 13.635 13.6276C13.2835 13.979 12.7136 13.979 12.3622 13.6276L9 10.2654L5.63784 13.6276C5.28636 13.979 4.71652 13.979 4.36504 13.6276Z" fill="#AFAFAF" />
                                                                            </svg>
                                                                            {/* <input id="close-special-insc" type="button" value="X" onClick={this.handleUpdateSpecialInstruction.bind(this, itm.uid, true)} /> */}
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            }
                                                        </div>
                                                    }
                                                </div>

                                            </div>


                                        )
                                    }
                                </div>
                                {(olDetail.enableSpecialInstruction) &&
                                    <div className="spec-info">
                                        {/* style by dasprakash on 19-10-2023 jira-1645 */}
                                        <input style={{ border: "none", borderRadius: "10px", height: '40px', paddingLeft: "15px", outline: "none", }} type="text" onChange={(e) => this.handleInstrucChange(e)} className="instc" id="input-bill-instruction" placeholder="Please provide any additional instructions for your order ?.." autoComplete="off" value={this.state.orderInstruction} />
                                        {this.state.closeButton &&
                                            // Commended by vijay on 04-11-2023
                                            // <svg onClick={() => this.setState({ orderInstruction: "", closeButton: false })} style={{ marginRight: "16px" }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            //     <path d="M1 14L14 0.999999" stroke="#FF0000" strokeWidth="2" strokeLinecap="round" />
                                            //     <path d="M14 14L1 1" stroke="#FF0000" strokeWidth="2" strokeLinecap="round" />
                                            // </svg>
                                            // Added by vijay on 04-11-2023
                                            <svg onClick={() => this.setState({ orderInstruction: "", closeButton: false })} style={{ marginRight: "16px" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM4.36504 13.6276C4.01357 13.2761 4.01357 12.7062 4.36504 12.3548L7.72721 8.9926L4.36217 5.62756C4.0107 5.27609 4.0107 4.70624 4.36217 4.35477C4.71364 4.0033 5.28349 4.0033 5.63496 4.35477L9 7.71981L12.365 4.35477C12.7165 4.0033 13.2864 4.0033 13.6378 4.35477C13.9893 4.70624 13.9893 5.27609 13.6378 5.62756L10.2728 8.9926L13.635 12.3548C13.9864 12.7062 13.9864 13.2761 13.635 13.6276C13.2835 13.979 12.7136 13.979 12.3622 13.6276L9 10.2654L5.63784 13.6276C5.28636 13.979 4.71652 13.979 4.36504 13.6276Z" fill="#AFAFAF" />
                                            </svg>

                                        }
                                    </div>
                                }
                                <div className="bill-details">
                                    <div className="heading">Bill Details</div>
                                    <div className="content">
                                        <span>Item Total</span>
                                        <span className="price"><Currency />{this.state.totalItemAmount.toFixed(2)}</span>
                                    </div>
                                    {/* <div className="content"><span>Charges </span><i className="fa fa-info-circle" aria-hidden="true"></i><span className="price">₹27.00</span></div> */}
                                    {/* //Added by vijay on 28-12-2023 Jira id -2075 */}
                                    {this.state.totalChargesAmount !== 0 ? (
                                        <div className="content">
                                            <span>Charges </span>
                                            <span className="price"><Currency />
                                                {/* {console.log('Total Charges Amount:', this.state.totalChargesAmount.toFixed(2))} */}
                                                {this.state.totalChargesAmount.toFixed(2)}</span>
                                        </div>
                                    ) : null}

                                    <div className="content tax">   {/* //Added by vijay on 28-12-2023 Jira id -2075 */}
                                        {this.state.totaltaxAmount !== 0 ? (
                                            <div> <span>Tax</span><span className="price">{this.state.totaltaxAmount.toFixed(2)}</span></div>
                                        ) : null}
                                        {this.state.roundOff !== 0 && <div className="content" style={{ lineHeight: "26px" }}><span>RoundOff </span><span className="price"><Currency />
                                            {/* {console.log('RoundOff Value:', this.state.roundOff.toFixed(2))} */}
                                            {this.state.roundOff.toFixed(2)}</span></div>}
                                    </div>

                                    <div style={{ color: "#000" }} className="content total"><span style={{ fontSize: "16px", fontWeight: "500" }}>To Pay</span><span style={{ fontSize: "15px", fontWeight: "500" }} className="price"><Currency />{this.state.totalNetAmount.toFixed(2)}</span></div>

                                </div>
                                {/* <h1 className="error-message">{this.state.errorMessage}</h1> */}
                                <div className="error-message" style={{ height: errorMessageHeight }}>{this.state.errorMessage}</div>

                                <AlertMessagePopup
                                    ref={alertMessageRef}
                                    message={this.state.alertMessages}                                                           >
                                </AlertMessagePopup>

                                {this.state.contentMessage && this.state.contentMessage.length > 0 ? (
                                    <div className="pay-div content-message">
                                        {this.handleContent()}
                                        <AlertMessagePopup ref={alertContentRef} message={this.state.contentMessage} />
                                    </div>
                                ) : null}


                                <div className="pay-div">

                                    {orderCheckoutType === enmConfig.orderCheckOutType.placeOrder &&

                                        <div onClick={this.handleValidateOrder.bind(this, 1, false)} style={{ padding: '18px' }}>
                                            {/* //commented by dasprakash on 19-10-2023 jira-1645 */}
                                            {/* <span>
                                                <img className="rupee" src="../assets/theme1/img/place-order.png" alt="lucid-place-order" />
                                            </span> */}
                                            Place Order
                                        </div>
                                    }
                                    {orderCheckoutType === enmConfig.orderCheckOutType.paynPlaceOrder &&

                                        <div onClick={this.handleValidateOrder.bind(this, 2, false)} style={{ padding: '18px' }}>
                                            {/* //commented by dasprakash on 19-10-2023 jira-1645 */}
                                            {/* <span>
                                                <img className="rupee" src="../assets/theme1/img/rupee.png" alt="lucid-rupee" />
                                            </span> */}
                                            Pay & Place Order
                                        </div>
                                    }

                                    {orderCheckoutType === enmConfig.orderCheckOutType.both &&
                                        <div>
                                            <div className="c-left" onClick={this.handleValidateOrder.bind(this, 1, false)}>
                                                {/* commented by dasprakash on 19-10-2023 jira-1645 */}
                                                {/* <span>
                                                    <img className="rupee" src="../assets/theme1/img/place-order.png" alt="lucid-place-order" />
                                                </span> */}
                                                Place Order
                                            </div>

                                            <div className="c-right" onClick={this.handleValidateOrder.bind(this, 2, false)}>
                                                {/* commented by dasprakash on 19-10-2023 jira-1645 */}
                                                {/* <span>
                                                    <img className="rupee" src="../assets/theme1/img/rupee.png" alt="lucid-rupee" />
                                                </span> */}

                                                Pay & Place Order
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    };
}