import React, { Component } from 'react';
import './qrcodeerror.scss';

export default class Orientation extends Component {           
    render()
    {
        return(
            <div>
            {/* {loading && <LoadingAnimation />}             */}
            <div id="login-content qrcode-err">
                <div className="content-wrap qrcode_bg">
                    {/* <img src={LoadingImg} className="svg" alt="" />  */}
                    {/* <img src="../assets/theme1/img/error/orientation-mode.png" className="orentation-img" alt="lucid-contactless-food-ordering" /> */}
                  
                    <div className="qrcode-txt-center">
                        {/* <img src="../assets/theme1/img/error/orientation-mode.png" style={{width: "50px"}} alt="lucid-device-error"/> */}
                        <div className="qrcode-oops-txt">Rotate Your Device </div>
                        {/* <div className="qrcode--txt-not-found">404 - Page Not Found.</div> */}
                        <div className="qrcode-msg-txt">We do not support landscape mode yet.
                        <br/> Please go back to portrait mode for the best experience.
                        </div>            
                                      {/* <div className="qrcode-msg-txt">The page you are looking for might have been removed <br/>
                        had its name changed or is temporarily unavailable.
                        </div> */}
                    </div>
                </div>
                {/* <img src={LoadingImg} className="svg screen-img-center loading_img" alt="" /> */}
            </div>
        </div>
        )
    }
}