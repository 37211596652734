import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import './cart.scss';
import uuid from 'react-uuid';
import LoadingAnimation from '../../components/loadinganimation';
import ErrorAlert from '../../components/alertmessages';
import Currency from '../currency/index';
import Config from '../../data/SiteConfig';
import sHelpers from '../../modules/helpers/statichelpers';
let serviceType = '';
let guestLoggedIn = false;
let urlPattern = '';
class CartDetail extends Component {

    constructor(props) {
        super(props)
        this.goCart = this.goCart.bind(this);
        this.goBill = this.goBill.bind(this);
        this.state = {
            showHide: false,
            getMenus: this.props.getMenus,
            getItems: this.props.getItems,
            loading: false,
            alertMessage: '',
            msgType: '',
            msgShow: false
        }
    }
   
      
    componentWillMount() {
            // console.log("this.props");          
        serviceType = sessionStorage.getItem('user-servicetype');
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
      
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        urlPattern = sessionStorage.getItem('url-pattern');
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
    }

    goCart() {

        /// user Session Validation        
        if (!sHelpers.userSessionValidation(this.props.userSessionId)) {
          // console.log(!sHelpers.userSessionValidation(this.props.userSessionId));
            this.showMessage("Your session has been expired, please try again", "danger");          
            setTimeout(() => {          
                this.props.history.push('/home' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }

        if (this.props.totalItems === 0) {
            this.showMessage("Cart is Empty", "danger");
            return false;
        }
        this.setState({ loading: true });

        sessionStorage.setItem('user-vendorOrderId', uuid());
        setTimeout(() => {
            this.setState({ loading: false });

          
            this.props.history.push('/cart' + urlPattern );
           // this.props.history.push('/cart' + urlPattern );
        }, Config.minMsgTimeout);
    }
    goBill() {
        if (guestLoggedIn === "true") {
            this.setState({ loading: true });
            setTimeout(() => {

                this.setState({ loading: false });
                this.props.history.push('/mybills' + urlPattern);
            }, Config.minMsgTimeout);
        }
        else {
            this.setState({ loading: true });
            sessionStorage.setItem("login-page-session", 7); // PayBill        
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/login' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
    }

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };
    render() {
        
        return (            
            <div onClick={this.goCart}>
                {this.state.loading && <LoadingAnimation />}
                {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                {serviceType !== 'FINE-DINE' &&
                    // <div className="cart-div" onClick={this.goCart} style={{ display: this.props.active }}>
                    //     <div className="c-left"> <span>{this.props.totalItems} </span> Items | <Currency/><span>{this.props.totalAmount.toFixed(2)}</span></div>
                    //     <div className="c-right">View Cart <span style={{ color: "#000" }}><i className="fa fa-shopping-cart"></i></span></div>
                    // </div>
                    // Added by vijay on 21-11-2023 Jira id 1741
                    <div className="cart-div">
                        <div className='cart-div-prices_v2'>
                            <span style={{fontSize:"16px",fontWeight:"600"}}>{this.props.totalItems} item | <Currency/>{Math.round(this.props.totalAmount)}</span>
                            <span style={{fontSize:"11px",fontWeight:"300"}}>Extra Charges may apply</span>
                        </div>
                        <div className="c-center"  >
                            <span>
                                View Cart
                            </span>
                        </div>
                    </div>
                }
                {serviceType === 'FINE-DINE' &&
                    <div className="cart-div">
                        {/* {!this.props.checkOutTypewithPay &&
                            <div className="c-left" onClick={this.goBill} ><span><img src="../assets/theme1/img/pay-bill.png" alt="lucid-post-paid" className="pay-bill-img" /></span><span>View Bill</span></div>
                        }
                        {this.props.checkOutTypewithPay &&
                            <div className="c-left" onClick={this.goBill} ><span><img src="../assets/theme1/img/pay-bill.png" alt="lucid-post-paid" className="pay-bill-img" /></span><span>Pay Bill</span></div>
                        } */}                        
                        <div className='cart-div-prices_v2'>
                        {/* {console.log('Total Items:', this.props.totalItems)} */}
                            <span style={{fontSize:"16px",fontWeight:"600"}}>{this.props.totalItems} item | <Currency/>{Math.round(this.props.totalAmount)}</span>
                            <span style={{fontSize:"11px",fontWeight:"300"}}>Extra Charges may apply</span>
                        </div>

                        <div className="c-center"  >
                            <span>
                                View Cart
                            </span>
                        </div>
                    </div>
                }

            </div>
        )
    }
}
export default withRouter(CartDetail)
                            // <span style={{ color: "#000" }}>

                            //     <i className="fa fa-shopping-cart"></i>
                            // </span> Place Order ( {this.props.totalItems} )
